import classplusLogo from "../../assets/Images/classplus-logo.png";
import classplusLogoColored from "../../assets/Images/classplus-logo-colored.png";
// import appImage from "../../assets/Images/app-image.png";
import facebook from "../../assets/Images/facebook.png";
import instagram from "../../assets/Images/instagram.png";
import youtube from "../../assets/Images/youtube.png";
import linkedin from "../../assets/Images/linkedin.png";
import twitter from "../../assets/Images/twitter.png";
import { useTranslation } from "react-i18next";

import "./thankyou.css";
// import KnowMoreSection from "../../components/KnowMoreSection";
import Features from "../../components/Features/Carousel";

const ThankYouScreen = ({ isDesktopView }) => {
  const { t } = useTranslation();
  return (
    <div className="thank_you_screen">
      <div>
        <img
          className="logo"
          width={150}
          src={classplusLogo}
          alt="classplus-logo"
        />
        <p className="thank_you">
          {t(
            "screens.ThankYouScreen.ThankYou",
            "Thank you for trusting us"
          )}
        </p>
        <p className="thank_you_text">
          {t(
            "screens.ThankYouScreen.Our Growth",
            "Our Growth executive will get in touch with you soon."
          )}
        </p>
      </div>

      {/* <div className="knowMoreContainer">
        <KnowMoreSection />
      </div> */}

      <div style={{ marginTop: "50px" }}>
        <Features isDesktopView={isDesktopView} />
      </div>

      {/* <div className="rectangle_1">
        
      </div> */}

      {/* <div>
        <p className="text_still_not_impressed">still not impressed?</p>
        <p className="text_customer_review">Don’t take our words, check what our customers have to say.</p>
      </div>
      
      <div className="rectangle_2">
        <p className="text_need_assurance">NEED MORE ASSURANCE?</p>
        <p className="text_media_appreciation">Classplus is appreciated by National and International Media</p>
      </div> */}

      <div className="footer">
        <div className="logoContainer">
          <img
            className="logo-colored"
            src={classplusLogoColored}
            alt="classplus-logo-colored"
          />
          <a
            href="https://classplusapp.com/contact"
            rel="noreferrer"
            className="text_contact_us"
            target="_blank"
          >
            {t(
            "screens.ThankYouScreen.Contact Us",
            "Contact Us"
          )}
          </a>
        </div>

        <div className="iconsContainer">
          <a href="https://www.facebook.com/classplusapp">
            <img className="" src={facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/classplus">
            <img className="" src={instagram} alt="facebook" />
          </a>
          <a href="https://www.youtube.com/channel/UCp0yDDnyu-C-0Bo_y1JbGtA">
            <img className="" src={youtube} alt="facebook" />
          </a>
          <a href="https://www.linkedin.com/company/classplus">
            <img className="" src={linkedin} alt="facebook" />
          </a>
          <a href="https://twitter.com/classplusapps">
            <img className="" src={twitter} alt="facebook" />
          </a>
        </div>

        {/* <p className="text_request_call">Request a call</p> */}
      </div>
    </div>
  );
};

export default ThankYouScreen;
