import InstituteCard from "../../components/InstituteCard/InstituteCard";
import Radar from "./Radar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { stateWiseData } from "../../data/stateWiseData";
import { useNavigate } from "react-router";
import axios from "axios";
import { baseUrl } from "../../utils/api";
import topYoutuberCategories from "../../data/topYoutubeCategories.json";
import { useTranslation } from "react-i18next";
import JoinWhatsappButton from "../../components/JoinWhatsappButton/JoinWhatsappButton"
import VivekBindraImage from "../../assets/Images/vivek-bindra.svg";
import {fetchGrowthAndWhatsapp} from "../../utils/index";
import whatsappIcon from "../../assets/Images/whatsapp.svg"

const joinWhatsappButtonStyles = (desktopView) => {
  const styleValue = {
      position: 'absolute',
      bottom: 0,
  }
  if(desktopView) {
    styleValue.width = "405px";
  }
  return styleValue;
}

const RadarScreen = ({ userInputs, uid, desktopView, communityUrl}) => {
  const [insititutes, setInstitues] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = async () => {
    try {
      let payload = {
        submitted: true,
        uuid: uid?.uuid,
        growthTipsSource: 'Bindra/yes',
      }
      fetchGrowthAndWhatsapp(communityUrl, payload, true);
      //window.location.href=communityUrl? `${communityUrl}` : 'https://clsps.link/iWBcv0';
      // navigate("/ThankYou");
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    let data = [];
    if (userInputs["youtuber-name"] || userInputs["online-course-name"]) {
      data = topYoutuberCategories.filter(
        (item) => item.category === userInputs["youtuber-categories"]
      );
      setInstitues(data);
    } else {
      data = stateWiseData.filter((item) => item.State === userInputs["state"]);
      setInstitues(data);
    }
  }, [userInputs]);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    adaptiveHeight: true,
  };

  console.log(insititutes, "kok");

  return (
    <div className={desktopView ? "mt-10" : ""}>
      {/* <img width="100%" src={Radar} /> */}
      {/* {desktopView ? (
        <p className="text-colorHeading text-4xl">
          {t("screens.RadarScreen.RadarScreen.theTop", "These are the top")}
          {insititutes.length}{" "}
          {userInputs["youtuber-name"] || userInputs["online-course-name"]
            ? t(
                "screens.RadarScreen.RadarScreen.contentCreater",
                "content creators in your category"
              )
            : t(
                "screens.RadarScreen.RadarScreen.educators",
                "educators in your regions"
              )}
        </p>
      ) : (
        ""
      )} */}

      <div className={`${!desktopView ? 'flex-justify-center mt-5' : 'mt-5'}`} style={{position: "relative"}}>
      <img src={VivekBindraImage} className="bindra-img" alt="vivek-bindra" />
      <div style={joinWhatsappButtonStyles(desktopView)}>
        <JoinWhatsappButton 
          handleClick={handleClick} 
          text={t("screens.RadarScreen.RadarScreen.joinWhatsappText","Click below to learn Free Growth Tips from India's Top Educators 👇")}
          buttonText={t("screens.RadarScreen.RadarScreen.joinWhatsappSubText","Join Our Growth Community")}
        />
      </div>
      
      {/* {desktopView ? (
        <div className="mx-auto w-1/2">
          <button
            onClick={handleClick}
            className="mt-10 bg-colorSecondary text-colorWhite px-4 py-3 rounded w-full mx-auto"
            style={{backgroundColor:"#25d366", position: 'absolute', bottom: 0, left:0}}
          >
            {t("screens.RadarScreen.RadarScreen.joinFamily","Click below to learn Free Growth Tips from India's Top Educators 👇")}
          </button>
        </div>
      ) : (
        <button
          onClick={handleClick}
          className="mt-10 bg-colorSecondary text-colorWhite px-4 py-3 w-full rounded"
          style={{backgroundColor:"#25d366", position: 'absolute'}}
        >
           {t("screens.RadarScreen.RadarScreen.joinOurFamily","Click below to learn Free Growth Tips from India's Top Educators 👇")}
         
        </button>
      )} */}
        {/* <Radar /> */}
      </div>

      {/* <div className="mt-5">
        <Slider {...settings}>
          {insititutes.map((item, index) => (
            <div key={index}>
              <InstituteCard item={item} />
            </div>
          ))}
        </Slider>
      </div> */}

      {/* {desktopView ? (
        <div className="mx-auto w-1/2">
          <button
            onClick={handleClick}
            className="mt-10 bg-colorSecondary text-colorWhite px-4 py-3 rounded w-full mx-auto"
            style={{backgroundColor:"#25d366",display:"flex"}}
          >
            <img src={whatsappIcon} alt="" style={{ marginRight: 10, height: 24}}/>
            {t("screens.RadarScreen.RadarScreen.joinFamily","Learn Secrets of India's Top Educators")}
          </button>
        </div>
      ) : (
        <button
          onClick={handleClick}
          className="mt-10 bg-colorSecondary text-colorWhite px-4 py-3 w-full rounded"
          style={{backgroundColor:"#25d366",display:"flex"}}
        >
          <img src={whatsappIcon} alt="" style={{ marginRight: 10, height: 24}}/>
           {t("screens.RadarScreen.RadarScreen.joinOurFamily","Learn Secrets of India's Top Educators")}
         
        </button>
      )} */}
    </div>
  );
};

export default RadarScreen;
