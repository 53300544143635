import React, { useContext } from "react";
import { AndroidScreen } from "./AndroidScreen";
import { ConversationContext } from "./Conversation";
import { Dropdown } from "./Dropdown";
import { Image } from "./Image";
import { Input } from "./Input";
import { MultiSelect } from "./MultiSelect";
import { Radio } from "./Radio";
import { Simple } from "./Simple";

export const Option = ({toggleStateDropdown}) => {
  const { type, msg } = useContext(ConversationContext);
  switch (type) {
    case "dropdown":
      return <Dropdown toggleStateDropdown={toggleStateDropdown} />;
    case "input":
      return <Input placeholder="Press enter to submit.." />;
    case "radio":
      return <Radio />;
    case "image":
      return <Image />;
    case "androidScreen":
      return <AndroidScreen />;
    case "multiSelect":
      return <MultiSelect />;
    case "simple":
      return <Simple msg={msg} />;
    default:
      return "";
  }
};
