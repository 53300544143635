export const redirectWebinarPage = (redirectionURL, placementParam) => {
    const originalURL = new URL(window.location.href);
    const redirectURL = new URL(redirectionURL);
  
    //SET THE INITIAL VALUES FOR utm source , medium and campaign
    redirectURL.searchParams.set("utm_source", placementParam);
    redirectURL.searchParams.set("utm_medium", "newwebsite");
    redirectURL.searchParams.set("utm_campaign", "EWebinar");
  
    originalURL.searchParams.forEach((value, key) => {
      //WE NEED TO KEEP MEDIUM SAME AS OLD FLOW THAT IS newwebsite
      if (key === "utm_medium") {
        redirectURL.searchParams.set(key, "newwebsite");
      } else {
        redirectURL.searchParams.set(key, value);
      }
    });
  
    // Check if originalURL has search parameters
    const hasSearchParams = originalURL.search !== "";
  
    if (hasSearchParams) {
      //SET A NEW UTM PARAM TO TRACK HEADER BUTTON CLICK
      redirectURL.searchParams.set("utm_placement", placementParam);
    }
  
    window.open(redirectURL.href, "_blank");
  };
  