import React from "react";

export const DropdownLowerPart = ({
  dropdownOptions,
  selectDropdownValue = () => {},
  toggleStateDropdown = () => {},
}) => {
  return (
    <div
      className="pt-4 z-50 right-0 dropdownModal h-full -bottom-40  fixed overflow-y-auto bg-colorDropdown w-full shadow-lg bg-white focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="py-1" role="none">
        {dropdownOptions?.map((option) => {
          return (
            <p
              href="#"
              className=" hover:bg-colorPrimary text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
              key={option?.key || option}
              onClick={(e) => {
                selectDropdownValue(option.id, option.value, "end");
              }}
            >
              {option.value}
            </p>
          );
        })}
      </div>
    </div>
  );
};
