import HomeScreen from "../HomeScreen/HomeScreen";
import ClassplusLogo from "../../assets/Images/classplus-logo.png";
import { AndroidScreen } from "../../components/AndroidScreen";
import { useState, useEffect } from "react";
import {conversationsEG } from "../../data/contents";
import { ButtonTexts } from "../../data/contents";
import SouravDadaImage from "../../assets/Images/sourav-dada.png";
import messageEdit from "../../assets/Images/message-edit.svg";
import playCircle from "../../assets/Images/play-cricle.svg";
import announce from "../../assets/Images/Announce.svg";
import name from "../../assets/Images/name.svg";
import globe from "../../assets/Images/globe.png";
import "./Style.css";
import { baseUrl } from "../../utils/api";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Header from "../../components/Header/Header";

const DesktopScreen = () => {
  const [uid , setUid] = useState("")
  const { t } = useTranslation();
  const [language, setLanguage] = useState("EN")
  const [headingTexts , setHeadingTexts] = useState()
  const [numberSubmitted, setNumberSubmitted] = useState(false);
  const [instituteName, setInstituteName] = useState("");
  const [headingText, setHeadingText] = useState();
  const [buttonText, setButtonText] = useState("English")
  const [showButton, setShowButton] = useState(false)
  // const [communityUrls, setCommunityUrls]=useState('https://clsps.link/iWBcv0')
  const [communityUrls, setCommunityUrls]=useState('https://clps.app/eNUp')
  const [apilanguage, setApilanguage]=useState()
  const [showDadaImage, setShowDadaImage] = useState(true);

  useEffect(() => {
    const url = new URL(window.location.href);
    let utm_source = url.searchParams.get("utm_source");
    let utm_campaign = url.searchParams.get("utm_campaign");
    let utm_medium = url.searchParams.get("utm_medium");
    let utm_content = url.searchParams.get("utm_content");
    let utm_term = url.searchParams.get("utm_term");
    let gclID = url.searchParams.get("gclid");
    getUUID(utm_campaign, utm_medium, utm_source, utm_content, utm_term, gclID);
  }, []);

  const hideDadaImage = () => {
    setShowDadaImage(false);
  }

  const getUUID = async (
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    utm_term,
    gclID
  ) => {
    try {
      const fbp = document.cookie
        .split(";")
        .filter((c) => c.includes("_fbp="))
        .map((c) => c.split("_fbp=")[1]);
      const fbc = document.cookie
        .split(";")
        .filter((c) => c.includes("_fbc="))
        .map((c) => c.split("_fbc=")[1]);

      let fbpCookie = fbp && fbp[0];
      let fbcCookie = fbc && fbc[0];

      const { data } = await axios.post(`${baseUrl}/growth/saveLead`, {
        utm_campaign,
        utm_medium,
        utm_source,
        utm_content,
        utm_term,
        gclId: gclID,
        fbp: fbpCookie,
        fbc: fbcCookie,
        leadIntent:"clp-old",
      });
      setUid(data?.data);
      const lang=data?.data?.languageDetails?.language?.toUpperCase() ? data?.data?.languageDetails?.language?.toUpperCase()  :"EN";
      if(!localStorage.getItem("lang")){
        localStorage.setItem('lang',lang)
      }
      let updateLocalLanguage =  localStorage.getItem('lang');
      setLanguage(updateLocalLanguage)
      i18n.changeLanguage(updateLocalLanguage);
      setApilanguage(lang)
      setHeadingText(conversationsEG?.[`${updateLocalLanguage}`][1]);
      setCommunityUrls(data?.data?.languageDetails?.communityUrl)
      setShowButton(lang != 'EN')
    } catch (err) {
      console.log(err);
    }
  };
  const handleLanguage = ()=>{
    if(localStorage.getItem('lang')=='EN')
    {
      localStorage.setItem('lang',apilanguage)
    } else{
      localStorage.setItem('lang',"EN")
    }
    window.location.href='/';
  }
  useEffect(()=>{
    if(localStorage.getItem('lang')=== 'EN')
    {
      setButtonText(ButtonTexts?.[`${apilanguage}`])
      setHeadingText(conversationsEG?.[`${localStorage.getItem('lang')}`]?.[1])
    }
  },[apilanguage])

  useEffect(() => {
    console.log(conversationsEG?.[`${localStorage.getItem('lang')}`]?.[1], "sanyaaa");
    i18n.changeLanguage(localStorage.getItem('lang'));
    setHeadingText(conversationsEG?.[`${localStorage.getItem('lang')}`]?.[1]);
    setHeadingTexts(conversationsEG?.[`${language}`]);
    let index = 0;
    const interval = setInterval(() => {
      if (index < headingTexts?.length - 1) {
        setHeadingText(headingTexts[index]);
        index++;
      } else {
        index = 0;
        setHeadingText(headingTexts?.[index]);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [language,headingTexts]);

  return (
    <>
    <Header />
    {
      showButton ? (
        <div className="nav-container">
      <div className="content-box">
        <p>We {language == "EN" ? 'can display' : 'are displaying'} this page in your regional language.</p>
        <div className="nav-lang-box" onClick={handleLanguage}>
          <img src={globe} alt="globe" />
          <p>{buttonText}</p>
        </div>
      </div>
    </div>
      ) : ""
    }
      <div className="desktopScreen mt-10 px-20">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <div className="fixed w-1/3">
              <img width={200} src={ClassplusLogo} alt="classplus-logo" style={{visibility:"hidden"}}/>
              <h2 className="text-4xl mt-6 text-colorHeading font-semibold">
                {instituteName ? (
                  `${t("screens.DesktopScreen.HI","Hi")}, ${instituteName}`
                ) : (
                  <>
                    {/* <span className="border-b-4  border-colorWhite">
                      Create
                    </span>{" "}
                    your Online <br /> presence with Classplus. */}
                    {t(
                      "screens.DesktopScreen.readyText",
                      "Are you ready to be a"
                    )}{" "}
                    <br />
                    <span className="text-colorWhite border-b-2 border-colorHeading ">
                      {headingText}?
                    </span>
                  </>
                )}
              </h2>
              {numberSubmitted ? (
                <div className="w-2/3 mt-10 desktopViewAndroid">
                  <AndroidScreen
                    desktopView={true}
                    userInputs={{}}
                    instituteName={instituteName}
                  />
                </div>
              ) : (
                <>
                  <p className="mt-8">
                    <span className="text-2xl">
                      Grow Your Online Teaching Brand
                    </span>
                  </p>
                  <div className="featureContainer">
                    <span className="textStyle">
                      <img src={messageEdit} className="imgStyle" />
                      Take Live Classes
                    </span>
                    <span className="textStyle">
                      <img src={playCircle} className="imgStyle" />
                      Sell Courses Online
                    </span>
                    <span className="textStyle">
                      <img src={announce} className="imgStyle" />
                      Get Marketing Assistance
                    </span>
                  </div>
                </>
              )}
            </div>

            {showDadaImage ? (<div className="fixed w-1/4 imgContainerNew">
              <img src={SouravDadaImage} alt="sourav-dada" />
            </div>) : null}
          </div>
          <div className="p-4">
            <HomeScreen
              desktopView={true}
              setNumberSubmitted={setNumberSubmitted}
              setInstituteName={setInstituteName}
              language={language}
              uids={uid}
              communityUrls={communityUrls}
              hideDadaImage={hideDadaImage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopScreen;
