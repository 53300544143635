import React, { useContext, useState } from "react";
import { ConversationContext } from "./Conversation";
import downArrow from "../assets/Images/downArrow.svg";
import { t } from "i18next";

export const Dropdown = (props) => {
  const { toggleStateDropdown } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { options, value, setValue, id, next, isDesktopView, states } =
    useContext(ConversationContext);
  // console.log(next, " <= NEXT", id);
  const dropdownOptions = options.length ? options : [];
  return (
    <div className="flex justify-end mt-2">
      <div className="relative left-5 inline-block text-left">
        <button
          disabled={!!value}
          type="button"
          className={`inline-flex shadow-sm px-4 bg-white  focus:outline-none items-center justify-start font-bold w-64  rounded-md ${value
              ? "bg-colorWhite text-colorSecondary font-bold justify-center justify-items-end h-10"
              : " border border-colorSecondary h-11"
            }   `}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => {
            if (dropdownOptions.length !== 0) {
              if (!isDesktopView) {
                const scrollContainer = document.getElementsByClassName(
                  "conversationContainerParent"
                )[0];
                const dropDownModalGeneral = document.getElementsByClassName(
                  "dropDownModalGeneral"
                )[0];

                scrollContainer?.style.setProperty("overflow-y", "initial");

                dropDownModalGeneral.style.setProperty("top", "-124px");
                dropDownModalGeneral.style.setProperty("max-height", "45vh");
              }

              setIsOpen(!isOpen);
            } else {
              toggleStateDropdown();
            }
          }}
        >
          {value || t("components.Dropdown.Choose option","Choose an option")}
        </button>
        {value ? <div className="answerArrow"></div> : ""}
      </div>
      <div
        className="z-50 right-0 overscroll-contain dropdownModal dropDownModalGeneral h-60 top-0 fixed max-h-screen overflow-y-auto bg-colorDropdown w-full shadow-lg bg-white focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
        style={isOpen ? { display: "block" } : { display: "none" }}
      >
        <div className="py-1" role="none">
          {dropdownOptions?.map((option) => {
            return (
              <p
                href="#"
                className=" hover:bg-colorPrimary text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
                data-next={option.next}
                key={option?.key || option}
                onClick={(e) => {
                  setValue(
                    id,
                    e.target.innerText,
                    true,
                    e.target.getAttribute("data-next") || next
                  );
                  if (!isDesktopView) {
                    const scrollContainer = document.getElementsByClassName(
                      "conversationContainerParent"
                    )[0];
                    scrollContainer.style.setProperty("overflow-y", "auto");
                  }

                  setIsOpen(false);
                }}
              >
                {option.value}
              </p>
            );
          })}
        </div>
      </div>

      <img className="relative right-4" alt="dropArrow" src={downArrow} />
    </div>
  );
};
