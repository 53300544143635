import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import CarousalImg1 from "../../assets/Images/Azad.webp";
import CarousalImg2 from "../../assets/Images/Sans.webp";
import CarousalImg3 from "../../assets/Images/Shine.webp";
import { useTranslation } from "react-i18next";

const Carousel = ({ isDesktopView }) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isDesktopView ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <Slider {...settings}>
      <div className="carousal">
        <div style={{ padding: "30px" }}>
          <img
            style={{ borderRadius: "8px 8px 0px 0px" }}
            src={CarousalImg1}
            alt=""
            className="carousal_img"
          />
          <div className="carousal_content">
            {/* <h3 className="carousal_content_heading">Video Portal</h3> */}
            <p
              className="carousal_content_text"
              style={{
                backgroundColor: "#ffffff",
                color: "#000000",
                borderRadius: "0 0 8px 8px",
                justifyContent: "center",
                padding: "20px",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {t(
                "components.Features.Carousel.Ravi Prakash Azad",
                "Ravi Prakash Azad Sir established Azad IAS Academy to remove the shortcomings when it comes to teaching for the Civil Service Examinations. Using his own branded mobile application, he ensures to provide quality classroom programs, tests, and study materials."
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="carousal">
        <div style={{ padding: "30px" }}>
          <img
            style={{ borderRadius: "8px 8px 0px 0px" }}
            src={CarousalImg2}
            alt=""
            className="carousal_img"
          />
          <div className="carousal_content">
            <p
              className="carousal_content_text"
              style={{
                backgroundColor: "#ffffff",
                color: "#000000",
                borderRadius: "0 0 8px 8px",
                justifyContent: "center",
                padding: "20px",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {t(
                "components.Features.Carousel.Lokesh kumar Sharma",
                "Lokesh Kumar Sharma Sir is a National Gold medalist in his graduation in Sanskrit (Hons). With his YouTube channel, Lokesh Sir teaches more than 2 Lakhs students and has been helping students across the country compete for various government examination and come out with flying colors."
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="carousal">
        <div style={{ padding: "30px" }}>
          <img
            style={{ borderRadius: "8px 8px 0px 0px" }}
            src={CarousalImg3}
            alt=""
            className="carousal_img"
          />
          <div className="carousal_content">
            <p
              className="carousal_content_text"
              style={{
                backgroundColor: "#ffffff",
                color: "#000000",
                borderRadius: "0 0 25px 25px",
                justifyContent: "center",
                padding: "20px",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {t(
                "components.Features.Carousel.Saeed Sir",
                "Saeed Sir is a well-known educator & professional in the states of Andhra Pradesh & Telangana. He has grown from 500 new students joining the offline classes in a year to more than 10,000 now. They also have a vast online base of 80,000 students in their mobile app."
              )}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="carousal">
        <img src={CSM} alt="" className="carousal_img" />
        <div className="carousal_content">
          <h3 className="carousal_content_heading">Online Test Portal</h3>
          <p className="carousal_content_text">
            Conducting online tests without the hassle of checking is now
            possible! Give your students enough practice by using our online
            test portal. Automatically upload all your question papers.
          </p>
          <button className="carousal_content_btn">
            <Link to="/features">Know more</Link>
          </button>
        </div>
      </div> */}

      {/* <div className="carousal">
        <img src={Appmin} alt="" className="carousal_img" />
        <div className="carousal_content">
          <h3 className="carousal_content_heading">
            Your Coaching’s Mobile App
          </h3>
          <p className="carousal_content_text">
            One single mobile app by your coaching institute's name that can
            help you in becoming a digital brand. Digitise all your academic
            activities through the platform and give your students and their
            parents with the experience they desire for!
          </p>
          <button className="carousal_content_btn">
            <Link to="/features">Know more</Link>
          </button>
        </div>
      </div> */}
    </Slider>
  );
};

export default Carousel;
