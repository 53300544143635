import { useTranslation } from "react-i18next";
export const ChatLoader = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mt-3 inline-flex items-center justify-center rounded-lg bg-colorSecondary w-24 h-10">
        <svg
          width="54"
          height="12"
          viewBox="0 0 54 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6" cy="6" r="6" fill="white">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle cx="27" cy="6" r="6" fill="white" fillOpacity="0.8">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle cx="48" cy="6" r="6" fill="white" fillOpacity="0.6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
      <br />
      <span className="my-1 loaderFontSize">{t("components.ChatLoader.typing","Sourav Dada is typing")}</span>
    </>
  );
};
