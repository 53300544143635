import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ThankYouScreen from "./screens/ThankYouScreen/ThankYouScreen";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import DesktopScreen from "./screens/DesktopScreen/DesktopScreen";
import { useEffect, useState } from "react";
import axios from "axios";

// const screenWidth = window.innerWidth;

// if(screenWidth > 700){
//   const url = new URL(window.location.href);
//   let utm_source = url.searchParams.get("utm_source");
//   let utm_campaign = url.searchParams.get("utm_campaign");
//   let utm_medium = url.searchParams.get("utm_medium");
//   window.location.href = `
//   https://classplusapp.com?utm_source=${utm_source ? utm_source : "direct"}&utm_medium=${utm_medium ? utm_medium : "convo-lp"}&utm_campaign=${utm_campaign ? utm_campaign: "online"}`
// }

const App = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 800px)" });
  useEffect(() => {
    regionAPICall();
  }, []);
  async function regionAPICall() {
    try {
      const resp = await axios.get(
        "https://api.classplusapp.com/v3/countryData/ip"
      );
      if (resp.status == 200) {
        const data = resp.data.data.countryCode;
        if (data?.toUpperCase() === "AU") {
          window.location.href = "https://classplusapp.com/au";
        } else if (data?.toUpperCase() === "SG") {
          window.location.href = "https://classplusapp.sg";
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Router basename="/">
      <Routes>
        <Route
          path="/"
          element={isSmallScreen ? <HomeScreen /> : <DesktopScreen />}
        />
        <Route
          exact
          path="/ThankYou"
          element={<ThankYouScreen isDesktopView={!isSmallScreen} />}
        />
        <Route
          path="/index.html"
          element={isSmallScreen ? <HomeScreen /> : <DesktopScreen />}
        />
      </Routes>
    </Router>
  );
};

export default App;
