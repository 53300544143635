import React, { createContext, useState, useEffect } from "react";

import { ChatLoader } from "./ChatLoader";
import { Option } from "./Option";
import { Question } from "./Question";

export const ConversationContext = createContext(undefined);

export const Conversation = ({
  id,
  question,
  type,
  options,
  setValue,
  msg,
  next,
  value,
  address,
  imageName,
  states,
  subtype,
  setIsNumberSubmitted,
  isNumberSubmitted,
  switchScreen,
  toggleStateDropdown,
  scrollToBottom,
  isDesktopView,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottom();
    }
  }, [isLoading, scrollToBottom]);

  return (
    <ConversationContext.Provider
      value={{
        subtype,
        isDesktopView,
        scrollToBottom,
        isNumberSubmitted: isNumberSubmitted,
        id: id,
        question: question,
        type: type,
        options: options,
        setValue: setValue,
        msg: msg,
        next: next,
        value: value,
        address: address,
        imageName: imageName,
        states: states,
        setIsNumberSubmitted: setIsNumberSubmitted,
        switchScreen: switchScreen,
      }}
    >
      <div
        className={` ${
          id === "name"
            ? "conversationFirst"
            : type === "simple"
            ? "conversation-simple"
            : "conversation"
        }`}
      >
        {isLoading && question ? (
          <ChatLoader />
        ) : !question ? (
          <Option toggleStateDropdown={toggleStateDropdown} />
        ) : (
          <>
            <Question question={question} />
            <Option toggleStateDropdown={toggleStateDropdown} />
          </>
        )}
      </div>
      {/* <ChatLoader /> */}
    </ConversationContext.Provider>
  );
};
