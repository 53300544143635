import React, { useContext, useEffect, useState, useRef } from "react";
import { ConversationContext } from "./Conversation";
import tick from "../assets/Images/tick.svg";

export const Input = ({ placeholder }) => {
  const textInput = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const { value, setValue, id, next, subtype } =
    useContext(ConversationContext);

  useEffect(() => {
    setTimeout(() => {
      textInput.current && textInput.current.focus();
    }, 1500);
  }, []);

  let renderInput = (
    <input
      ref={textInput}
      required
      value={value || ""}
      className="bg-colorPrimary  rounded-lg w-64  h-12  border-solid border border-colorWhite outline-none"
      type={subtype || "text"}
      onChange={(e) => {
        if (subtype === "number" && e.target.value.trim().length <= 10) {
          setValue(id, e.target.value, false, "");
        } else if (subtype === "text" && e.target.value.trim().length >= 0) {
          setValue(id, e.target.value, false, "");
        } else if (subtype === "email" && e.target.value.trim().length >= 0) {
          setValue(id, e.target.value, false, "");
        }
      }}
    ></input>
  );
  return isSubmit ? (
    <div className="grid justify-items-end relative">
      <p className="bg-colorWhite mt-2  p-2  rounded w-4/5 text-center font-semibold text-base  text-colorSecondary ">
        {value}
      </p>
      {value ? <div className="answerArrow"></div> : ""}
    </div>
  ) : (
    <form
      className="flex justify-end py-4 relative"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          subtype === "number" &&
          value.match("[0-9]{10}") &&
          value.trim().length === 10
        ) {
          setValue(id, value, true, next);
          setIsSubmit(true);
        } else if (subtype === "text" && value.trim().length) {
          setValue(id, value, true, next);
          setIsSubmit(true);
        } else if (subtype === "email" && value.trim().length) {
          setValue(id, value, true, next);
          setIsSubmit(true);
        }  else {
          console.log("Enter 10 digits");
        }
      }}
    >
      {renderInput}
      <hr className=" absolute" />
      <button
        disabled={!value.trim().length}
        className={`flex w-12 rounded-lg justify-center items-center absolute h-12 ${
          value.trim().length ? "bg-colorSecondary" : "bg-colorWhite"
        }  `}
      >
        <img
          src={tick}
          alt="tick"
          className={`w-6 ${value.trim().length ? "tick" : ""}`}
        />
      </button>
    </form>
  );
};
