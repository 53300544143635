import React, { useContext } from "react";
import { Radio } from "./Radio";
import { ConversationContext } from "./Conversation";

export const Image = () => {
  const { imageName, address } = useContext(ConversationContext);
  return (
    <div className="m-4">
      <img
        className="sm:w-4/5 md:w-3/4 lg:w-2/4"
        alt={imageName}
        src={address}
      ></img>
      <Radio />
    </div>
  );
};
