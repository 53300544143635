// import { Carousel } from "@classplus-engineering/components";
import React from "react";
import "../androidStyles.css";
import playStore from "../assets/Images/playstore.png";
import CoachingScreen from "../assets/Images/CoachingScreen.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDisplayName, initials } from "../utils/index";
import chat from "../assets/Images/chat.png";
import store from "../assets/Images/store.png";
import smile from "../assets/Images/smilingFace.png";

export const AndroidScreen = ({ userInputs, instituteName }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    adaptiveHeight: true,
    nextArrow: "",
    prevArrow: "",
  };

  return (
    <>
      <div className="AndroidMobile">
        <Slider {...settings}>
          <div className="relative">
            <img
              className="playstore "
              alt="playstore"
              src={playStore}
              width="100%"
            />
            <div className="flex justify-between absolute top-20 left-8 playstoreContainer">
              <span className=" playstoreIcon rounded-xl w-16 h-16  bg-colorPlaystoreIcon text-colorWhite font-thin text-xs text-center flex justify-center items-center">
                {initials(
                  instituteName ||
                    userInputs["coaching-name"] ||
                    userInputs["youtuber-name"] ||
                    userInputs["teacher-name"] || 
                    userInputs["online-course-name"]
                )}
              </span>
              <div className="flex flex-col justify-center items-baseline">
                <span className=" break-words playstoreDisplayName w-60  text-sm text-colorPrimary font-semibold px-2 inline-flex">
                  {getDisplayName(
                    instituteName ||
                      userInputs["coaching-name"] ||
                      userInputs["youtuber-name"] ||
                      userInputs["teacher-name"] || 
                      userInputs["online-course-name"]
                  )}
                </span>
                <span className="text-colorGreen eduacationMedia">
                  Education Media
                </span>
              </div>
            </div>
          </div>
          <div className="relative">
            <img alt="coachingScreen" src={CoachingScreen} width="100%" />
            <span className="absolute androidCoachingName w-60 top-14 left-4 right-4 text-colorBlack text-center flex justify-center items-center">
              {getDisplayName(
                instituteName ||
                  userInputs["coaching-name"] ||
                  userInputs["youtuber-name"] ||
                  userInputs["teacher-name"] || 
                  userInputs["online-course-name"]
              )}
            </span>
          </div>
          <div className=" relative">
            <img alt="smile" src={smile} width="100%" />
          </div>
          <div className=" relative">
            <img alt="store" src={store} width="100%" />
          </div>

          <div className="relative">
            <img alt="chat" src={chat} width="100%" />
          </div>
        </Slider>

        {/* <Carousel
          activeSlide={0}
          dotsDisabled
          elementWidth={2560}
          elements={[
            
          ]}
          isBannerType
          listStyle={{
            marginLeft: "0 !important",
            marginRight: "0 !important",
            padding: "0 !important",
          }}
          width="100%"
        /> */}
        {/* <div className="androidScreen"></div> */}
      </div>
    </>
  );
};
