import React, { useContext } from "react";
import { ConversationContext } from "./Conversation";

export const Radio = () => {
  const { options, value, setValue, id, next} =
    useContext(ConversationContext);
  return (
    <div className="grid justify-items-end">
      {options.map((option) => {
        return (
          <button
            disabled={!!value}
            key={option?.value}
            className={`mt-2 p-2  rounded w-4/5 font-semibold ${
              value === option.value
                ? " bg-colorWhite text-colorSecondary border-0"
                : "bg-colorTransparent border-2 border-colorSecondary text-colorWhite"
            }`}
            onClick={(e) => {
              // if(key === "isOnlineTutor")
              if(sessionStorage.getItem("poster-triggered") === "true") {
                sessionStorage.setItem("poster-triggered", false);
              }
              // console.log("option.next => ", option?.next);
              // if (e.target.innerText === value) {
              //   return setValue(id, "", true, option?.next || next);
              // }
              setValue(
                id,
                e.target.innerText,
                true,
                option?.next || next,
                "radio",
                option.key
              );
            }}
          >
            {option?.value || option}
          </button>
        );
      })}
      {value ? <div className="answerArrow"></div> : ""}
    </div>
  );
};
