export const stateWiseData = [
  {
    App_Name: "Career Quest",
    City: "Port Blair",
    State: "Andaman And Nicobar Islands",
    App_Link: "https://clp.page.link/nvyB",
    Download: 2889,
    Logo: "https://play-lh.googleusercontent.com/ktUXvrBaLWtEpLE9IMTGCFX175tN_9qDQnAJfXwM1ZhQ6KpeSSBqcrDO52_1XHY3yns=s360-rw",
  },
  {
    App_Name: "Maa Durga Coaching",
    City: "Port Blair",
    State: "Andaman And Nicobar Islands",
    App_Link: "https://sansa.page.link/y9FH",
    Download: 1,
    Logo: "https://play-lh.googleusercontent.com/0LLSLqKorXSdCqD7YVqWdhLyTXwrvimmZqiY580vueRV5bkGredfaPDuJMSGpzJYauI=s180-rw",
  },
  {
    App_Name: "YES & YES PUBLICATIONS",
    City: "Vijayawada",
    State: "Andhra Pradesh",
    App_Link: "https://clp.page.link/PZ5J",
    Download: 115006,
    Logo: "https://play-lh.googleusercontent.com/_jbRGHvIblmhFXwpGEBoCJZOI3ttva3YzmicAi_JpfumsaUVz5_GuVkjhPqx30KDF74=s180-rw",
  },
  {
    App_Name: "iRise Online Classes",
    City: "Hyderabad",
    State: "Andhra Pradesh",
    App_Link: "https://eduthor.page.link/ETja",
    Download: 81375,
    Logo: "https://play-lh.googleusercontent.com/26ukjaHfgpJcK1m0SAW8K06c4nnsurzfWPimTEGUOEw3Z52JjGXLb0oiAA13Orkf8kI=s180-rw",
  },
  {
    App_Name: "VMR Logics",
    City: "Hyderabad",
    State: "Andhra Pradesh",
    App_Link: "https://eduthor.page.link/ioKV",
    Download: 82736,
    Logo: "https://play-lh.googleusercontent.com/L4JnvFNKfBRbdIuV0eLKG4RnJezJAQ9o6EJehPzHsY3v1zwLGfU44EKaQxgZTtCU-qk=s180-rw",
  },
  {
    App_Name: "Bookbird Academy",
    City: "Itanagar",
    State: "Arunachal Pradesh",
    App_Link: "https://aalicia.page.link/T3gQ",
    Download: 1773,
    Logo: "https://play-lh.googleusercontent.com/TNCZn6x71p1kwMV1Kj28NTaBxN22OvHpC0BfouLX33fGd-ZfPBE26OPDKwjFvVsMDTw=s180-rw",
  },
  {
    App_Name: "Sourav Chandel",
    City: "Hamirpur",
    State: "Arunachal Pradesh",
    App_Link: "https://edustan.page.link/3Axu",
    Download: 2027,
    Logo: "https://play-lh.googleusercontent.com/tI0gp4TsbjXaZuiFIqiSTbi4km9WUUoUsTMuS1k_jCyc9uF-UPO9hhQo8OAk3gX9qw=s180-rw",
  },
  {
    App_Name: "IBE",
    City: "Arunachal Pradesh",
    State: "Arunachal Pradesh",
    App_Link: "https://eduthor.page.link/izwK",
    Download: 1086,
    Logo: "https://play-lh.googleusercontent.com/r5reRAVwMA6j8rUoOwK4uC-Uu5XwXHY69Sirxg78k-KO8OBEBefIOCFos16qNV0UZcI=s180-rw",
  },
  {
    App_Name: "Jagati Digital Education",
    City: "Guwahati",
    State: "Assam",
    App_Link: "https://edunick.page.link/aaoo",
    Download: 27369,
    Logo: "https://play-lh.googleusercontent.com/-tAE4PWGMbKICBf1wl1eJClGe8nWUtcqzMRU69J1qPG73MzGVVUiT9OFiEkq-KyOOw=s180-rw",
  },
  {
    App_Name: "The PCB Point",
    City: "Guwahati",
    State: "Assam",
    App_Link: "https://edunick.page.link/vSHU",
    Download: 17432,
    Logo: "https://play-lh.googleusercontent.com/jENgbsxUNcuWLDmxIOPSfLE4lpQVzzK8hr3pCgJ8Uku2GO_NjneEIazhZFqmVqdSKzY=s180-rw",
  },
  {
    App_Name: "CSAP",
    City: "Guwahati",
    State: "Assam",
    App_Link: "https://treeloki.page.link/nf8G",
    Download: 3227,
    Logo: "https://play-lh.googleusercontent.com/05dfGnSQLCM8hs-Hdp_HXd1hqF5yfuQYwM676nxun9Heub8vxihaFY9s-xae8-YNFSc=s180-rw",
  },
  {
    App_Name: "Jai mechanical",
    City: "Patna",
    State: "Bihar",
    App_Link: "https://edushield.page.link/HLnN",
    Download: 5149,
    Logo: "https://play-lh.googleusercontent.com/05dfGnSQLCM8hs-Hdp_HXd1hqF5yfuQYwM676nxun9Heub8vxihaFY9s-xae8-YNFSc=s180-rw",
  },
  {
    App_Name: "Smriti Jupiter",
    City: "Patna",
    State: "Bihar",
    App_Link: "https://varys.page.link/6Wsw",
    Download: 9180,
    Logo: "https://play-lh.googleusercontent.com/7W5SfS7ahzs2tdPLwlcEp3xALEq2XTbmMwKaLK8eVE7p6Oq3NuMDKHWmbbsXzz-3-BLR=s180-rw",
  },
  {
    App_Name: "Aim Civil Services",
    City: "Patna",
    State: "Bihar",
    App_Link: "https://edunick.page.link/dMSp",
    Download: 18034,
    Logo: "https://play-lh.googleusercontent.com/Fg5a3kTbUGqr03J6SuX4KD2lAm2fRMhDZKcRcLuvMsvBKL0MJPSe64n7ExbW-hVpoCg=s180-rw",
  },
  {
    App_Name: "O2 IAS Academy",
    City: "Chandigarh",
    State: "Chandigarh",
    App_Link: "https://clp.page.link/XyZJ",
    Download: 14577,
    Logo: "https://play-lh.googleusercontent.com/4EkB-UmZHSjx7WrkXRBIZUPqA7OhWJioujh9SoMez6VZ9hiEsTBg6pmY6tU3EOVPocE6=s180-rw",
  },
  {
    App_Name: "Gyanam",
    City: "Chandigarh",
    State: "Chandigarh",
    App_Link: "https://gbolton.page.link/ZdVk",
    Download: 49035,
    Logo: "https://play-lh.googleusercontent.com/WAygtNFyZimDoX6L0r-NDQQudoZ0tWZg4PcGhseYs1UgUFORmvm5fj0WXh7i_4792yc=s180-rw",
  },
  {
    App_Name: "MIM Academy",
    City: "Chandigarh",
    State: "Chandigarh",
    App_Link: "https://eduthanos.page.link/kr6g",
    Download: 3534,
    Logo: "https://play-lh.googleusercontent.com/sJZtrnGK2GS9YTDQ6RVcIEuvZrpZQIC-zYRyrMhH6FXhdr6behC18pQzPDywoo9lhXk=s180-rw",
  },
  {
    App_Name: "Ankit Stocks Intrinsic Value (First in India by Market Mestroo)",
    City: "Bhilai",
    State: "Chhattisgarh",
    App_Link: "https://eduthor.page.link/x7gt",
    Download: 8817,
    Logo: "https://play-lh.googleusercontent.com/ALQdXudo0ofG5tcMDCWUgkHd9dML9IrwrKw0K7y0XtCUR_EwOp9Po0ksy9HD1K3fRdc=s180-rw",
  },
  {
    App_Name: "Arjuna Classes 'Digital'",
    City: "Bilaspur",
    State: "Chhattisgarh",
    App_Link: "https://eduthanos.page.link/nf9s",
    Download: 9109,
    Logo: "https://play-lh.googleusercontent.com/_3iscpbkDsQ2cGkxRTIKM89HWl89Eb2tmQHQDMhHGXmhsHLLmDax28Wwdh0JqugGpAA=s180-rw",
  },
  {
    App_Name: 'COMPETITION ACADEMY"DIGITAL CLASSES',
    City: "Bilaspur",
    State: "Chhattisgarh",
    App_Link: "https://cbran.page.link/6u1A",
    Download: 20197,
    Logo: "https://play-lh.googleusercontent.com/rfMuf4mqUP81hYVYErrbfCq6iI_TBRwhrlJmkXwUswUMni29t6bHAT2KT-zD26yFbvI=s180-rw",
  },
  {
    App_Name: "e-Sanskruti Learning",
    City: "Aurangabad",
    State: "Dadra And Nagar Haveli",
    App_Link: "https://eduthanos.page.link/pdod",
    Download: 38,
    Logo: "https://play-lh.googleusercontent.com/5VovArqcxLpEVfPrySFyP3jwDHbT5UwJjMrNi7AuFefLzHOiBOinOeW_ZbhuiWvN3W6o=s180-rw",
  },
  {
    App_Name: "EduSCAS",
    City: "Port Blair",
    State: "Daman And Diu",
    App_Link: "https://eduthor.page.link/ndq8",
    Download: 186,
    Logo: "https://play-lh.googleusercontent.com/NuoJV6cpYTDYWmoaNEWGtIJGVrBN83c9qWNRKEbsBJ_hTR_USytA71iXiidTxjMHrLA=s180-rw",
  },
  {
    App_Name: "PATEL ACEDEMY",
    City: "Daman",
    State: "Daman And Diu",
    App_Link: "https://edunick.page.link/bjFD",
    Download: 24,
    Logo: "https://play-lh.googleusercontent.com/82VNsLWDiOjVjrIj4Qj3Tp17fzLGJuxiZDoeINzxESBPUL8P9CK-5wK-cvxmTUF0r1M=s180-rw",
  },
  {
    App_Name: "i-BOX",
    City: "Diu",
    State: "Daman And Diu",
    App_Link: "https://tarly.page.link/mH8T",
    Download: 17,
    Logo: "https://play-lh.googleusercontent.com/-eXoi8HROLsjRaXQHO7kxUGLjTs9jQBdTu6U7tkSGfONwUPLwbtc14-2inqtpytAfA=s180-rw",
  },
  {
    App_Name: "NEET UDAAN",
    City: "Delhi",
    State: "Delhi",
    App_Link: "https://edustan.page.link/xBuJ",
    Download: 150322,
    Logo: "https://play-lh.googleusercontent.com/sdxr4IDqtbTFJBvOMRpVIG3iXr54cvm1Bg1MMOvIILDqEtkxdiDIRR8MEG30mPqEZQ=s180-rw",
  },
  {
    App_Name: "LUKMAAN IAS",
    City: "East Delhi",
    State: "Delhi",
    App_Link: "https://eduthanos.page.link/ykcV",
    Download: 1847,
    Logo: "https://play-lh.googleusercontent.com/pP987DMHPaQvzHvj3MMI98YoK72Hj99iLDD_sWaNnJDLtNZCaSPRX7D_YhuWoz8U638=s180-rw",
  },
  {
    App_Name: "INSIGHT SSB",
    City: "Gopal Nagar",
    State: "Delhi",
    App_Link: "https://edukevin.page.link/LkiG",
    Download: 49442,
    Logo: "https://play-lh.googleusercontent.com/9mOZEFKeyRr8zZFxg-IPVNlLuSs5HGrSgNjKFbM5N4-c0QToGix0eKKrejLqOpLNY9As=s180-rw",
  },
  {
    App_Name: "Quest Educare",
    City: "Goa",
    State: "Goa",
    App_Link: "https://edunick.page.link/uGCN",
    Download: 464,
    Logo: "https://play-lh.googleusercontent.com/JhGo8Z2HxCVsny5LYI0X54eS_rJUnhseL22zWxFy8GXrvWs_hLUTIuRxMSj1JeaPspA=s180-rw",
  },
  {
    App_Name: "Eureka Education E ^ 2",
    City: "Goa",
    State: "Goa",
    App_Link: "https://jorah.page.link/D1P1",
    Download: 595,
    Logo: "https://play-lh.googleusercontent.com/8tf1vdjemRMA6n-Pa3XFoWifwusAw5G8bd0cAwNz_AfN9eFAm7ZfkpFSt2EVDVnPF9O4=s180-rw",
  },
  {
    App_Name: "Gamma Impact",
    City: "North Goa",
    State: "Goa",
    App_Link: "https://davos.page.link/47dG",
    Download: 186,
    Logo: "https://play-lh.googleusercontent.com/HW_pjOAyTB1aoZSNvQwdjmshh02roLb8Y3DWqdK9UD9OmgIA4J_CRwex_NwXoCwGOQ=s180-rw",
  },
  {
    App_Name: "Finnacle Shah Classes",
    City: "Surat",
    State: "Gujarat",
    App_Link: "https://clp.page.link/eUGa",
    Download: 1804,
    Logo: "https://play-lh.googleusercontent.com/SPQmKzveI5BV3fI-uwR6f0Ikjs80OikhS8JsAKOQoddvOcKng-x9HzRMC85pmB-OPUE=s180-rw",
  },
  {
    App_Name: "Webdemy",
    City: "Sabar Kantha",
    State: "Gujarat",
    App_Link: "https://skyarya.page.link/RP9M",
    Download: 26720,
    Logo: "https://play-lh.googleusercontent.com/XSCgPrm1q3iGniFwTeR6E-AwU4LxmVlfyICnxQSvXxERp3CPidwoHtvHywompcXoy0ul=s180-rw",
  },
  {
    App_Name: "Bhoraniya UPSC / GPSC Guidance",
    City: "Ahmedabad",
    State: "Gujarat",
    App_Link: "https://varys.page.link/5Sav",
    Download: 12269,
    Logo: "https://play-lh.googleusercontent.com/1E_SlhEC7jhkT9mpp3ai4Q6D0iqwOrNpxlUc1FISJAcrpJETzKLovFcdraXpDkU-5w=s180-rw",
  },
  {
    App_Name: "MISSION DSSSB",
    City: "Haryana",
    State: "Haryana",
    App_Link: "https://clp.page.link/kNV2",
    Download: 27587,
    Logo: "https://play-lh.googleusercontent.com/vIzVbAYFUvD8CK909yUkR2avdjnWvz9DCB8oEQzxcFlNyHc0UKolGiaJNfsYY8f9-60=s180-rw",
  },
  {
    App_Name: "Gautam Singla Sir",
    City: "Jind",
    State: "Haryana",
    App_Link: "https://edushield.page.link/5yCp",
    Download: 6601,
    Logo: "https://play-lh.googleusercontent.com/I9VAYkfp5FLtIRAFEoWi42US2rToxw5wph9t8_juOqpolvkoWoRTisiT3oNUiqocnXY=s180-rw",
  },
  {
    App_Name: "Gillz Mentor",
    City: "Panchkula",
    State: "Haryana",
    App_Link: "https://eduiron.page.link/eCUg",
    Download: 22832,
    Logo: "https://play-lh.googleusercontent.com/ByGo1NQeTlyGw_0eGoX6TulsP6avnoQ2EdLtd46u07q9dH___z679UOLfzRJH1wujOM=s180-rw",
  },
  {
    App_Name: "VR Academy",
    City: "Hamirpur",
    State: "Himachal Pradesh",
    App_Link: "https://edukevin.page.link/NBaa",
    Download: 2934,
    Logo: "https://play-lh.googleusercontent.com/D7Li5RSsEtG_AFvSBfAjabTYeRdr6K_JYKl9WgvH-c6dJpDI_fPmGJffSM8KxeC1wkM=s180-rw",
  },
  {
    App_Name: "Rana Education",
    City: "Shimla",
    State: "Himachal Pradesh",
    App_Link: "https://edunick.page.link/X2Dp",
    Download: 2190,
    Logo: "https://play-lh.googleusercontent.com/-K7CfoMcMehJ0-Qpu8ANhKxK-GEpn5QB_KzBh4RWxlpdMPHuBNQb7WF7ZGgwRWiw87JF=s180-rw",
  },
  {
    App_Name: "APC ACADEMY",
    City: "Bilaspur",
    State: "Himachal Pradesh",
    App_Link: "https://hodor.page.link/cRv3",
    Download: 1286,
    Logo: "https://play-lh.googleusercontent.com/DhwyVrFtpWQ4RSMR1gGGZQv8oSLcBT_-YvxEtaTTIzzsZ04Xxnd6kbD8wfxRrgeVyfLy=s180-rw",
  },
  {
    App_Name: "JK EXAM CRACKER",
    City: "Jammu",
    State: "Jammu And Kashmir",
    App_Link: "https://eduiron.page.link/MtP5",
    Download: 34506,
    Logo: "https://play-lh.googleusercontent.com/_HPz4e-GL_GYMpfJGYbvbkgrY27-MEYfUT6hfTFOVGHcdo_TXrlx6m5DtY2iPqnG7Q=s180-rw",
  },
  {
    App_Name: "JK CIVIL SERVICES",
    City: "Anantnag",
    State: "Jammu And Kashmir",
    App_Link: "https://edukevin.page.link/QPXN",
    Download: 14503,
    Logo: "https://play-lh.googleusercontent.com/ftL9jNkY_e8JcaaWBu72d48E6NMfweuiuum23DFnuSq4Q9x8ODcYf2Xy3jLOo2AfztMX=s180-rw",
  },
  {
    App_Name: "Quintessence Classes",
    City: "Srinagar",
    State: "Jammu And Kashmir",
    App_Link: "https://davos.page.link/EEgR",
    Download: 6197,
    Logo: "https://play-lh.googleusercontent.com/lEu0Epu-YDdSln85OnOBgflGlJRRKjcn-JWS2ReQ-q_wsuJgvHW2hg76KddXbHK2_g=s180-rw",
  },
  {
    App_Name: "Svaroop Learning App",
    City: "Ranchi",
    State: "Jharkhand",
    App_Link: "https://edushield.page.link/Ymue",
    Download: 944,
    Logo: "https://play-lh.googleusercontent.com/ieO6KfZXgVKTvPWRnP-zqHhfWVSPKFy4yU7CF7fSV3mmcNh2O3ISo5itnUYdQksHDJHo=s180-rw",
  },
  {
    App_Name: "MicroCADD",
    City: "Bokaro",
    State: "Jharkhand",
    App_Link: "https://lynde.page.link/58mV",
    Download: 44618,
    Logo: "https://play-lh.googleusercontent.com/5JiMLEapMWTPZVvwdrS5oGzmJrnJKstDjM6UJTVMsLVujed3jHDQSkGTrQLMNjc2kW0=s180-rw",
  },
  {
    App_Name: "Mining Technical",
    City: "Ramgarh",
    State: "Jharkhand",
    App_Link: "https://eduiron.page.link/ow48",
    Download: 6462,
    Logo: "https://play-lh.googleusercontent.com/qjgvU1LiZpdJpVKEf-LwvDLcXHVjob2bwBEDedt5dI1DUjvl1BR1ehBAdLJr3piH6F0=s180-rw",
  },
  {
    App_Name: "Ramesh Soni Classes",
    City: "Bangalore",
    State: "Karnataka",
    App_Link: "https://eduthanos.page.link/viD3",
    Download: 15404,
    Logo: "https://play-lh.googleusercontent.com/EM5Q_h3YPdtEP4eWM-GnpgWg9LwQaIdEcTs02lTQQTl5IvTQ1m3pKhUD-gYu0qc6-mA=s180-rw",
  },
  {
    App_Name: "OnlineStudy4U",
    City: "Bangalore",
    State: "Karnataka",
    App_Link: "https://varys.page.link/7Q3X",
    Download: 42869,
    Logo: "https://play-lh.googleusercontent.com/ms-F1_1q5V0UorrLWwtYIlH8wF44xqRcO5IumS-tlhjpEMECoTBj83P2ss3cFKnzkRw=s180-rw",
  },
  {
    App_Name: "SimplifiedMinds",
    City: "Bangalore",
    State: "Karnataka",
    App_Link: "https://hodor.page.link/uSqP",
    Download: 23655,
    Logo: "https://play-lh.googleusercontent.com/ADEz7fHXKpmjyo16Kn_feB64y24K824__ERXsd281S7rBRakAaRXPH19cusOOm2r4P8=s180-rw",
  },
  {
    App_Name: "Samson's Medlin Academy",
    City: "Kochin Kerala",
    State: "Kerala",
    App_Link: "https://eduiron.page.link/ReZ6",
    Download: 3421,
    Logo: "https://play-lh.googleusercontent.com/F524rjKv63kc77wwCCpKS6tnU4FeOdVwywi6NMuyzcPlLjo5c4PVM1RxoyIlKjfPUQc=s180-rw",
  },
  {
    App_Name: "MASTER KEY THE LEARNING APP",
    City: "Kollam",
    State: "Kerala",
    App_Link: "https://edushield.page.link/Beva",
    Download: 3084,
    Logo: "https://play-lh.googleusercontent.com/tEP-ecFUba9Uowoxe3fob0z_k3CULhNliIc5ETS01u6t49dAMS7wYSHm-MviDnoUA38W=s180-rw",
  },
  {
    App_Name: "CTune's Learning App",
    City: "Calicut",
    State: "Kerala",
    App_Link: "https://edunick.page.link/FfNj",
    Download: 3575,
    Logo: "https://play-lh.googleusercontent.com/TvumYU2-YtuBk_g8SDU59FaNrFzZKX7wJWawsiga8jnCU0c5n0QvWX33P2wV0qXKsw=s180-rw",
  },
  {
    App_Name: "Tulips",
    City: "Andamans",
    State: "Lakshadweep",
    App_Link: "https://edumartin.page.link/9FvM",
    Download: 6,
    Logo: "https://play-lh.googleusercontent.com/fgz0hngyf0_S4N-cLBCE69M0yUSCG8wrUsIOWLW31CTvN2bckPKYvo_Zs-AzMQ0NjKXr=s180-rw",
  },
  {
    App_Name: "Commerce Baba",
    City: "Bhopal",
    State: "Madhya Pradesh",
    App_Link: "http://bit.ly/2Mg2bwA",
    Download: 100252,
    Logo: "https://play-lh.googleusercontent.com/C_dQL3VD5jFCRAgSms9anzDZXQvNFnwWXA4N3KIkzXptP-FDPDHDxeyeAb0QPeP3IoU=s180-rw",
  },
  {
    App_Name: "Teach Tech",
    City: "Bhopal",
    State: "Madhya Pradesh",
    App_Link: "https://clp.page.link/Tz4d",
    Download: 37427,
    Logo: "https://play-lh.googleusercontent.com/mUxotBzWK2l8y_0K-TkOAwHCF9QGxOLq314j5TF7wxdHzTWLKLvsgOYnpTejJB4ISlNX=s180-rw",
  },
  {
    App_Name: "Dr. English",
    City: "Bhopal",
    State: "Madhya Pradesh",
    App_Link: "https://jorah.page.link/RrnH",
    Download: 33514,
    Logo: "https://play-lh.googleusercontent.com/PT_LntuMalx0eZe6TSCs4wru-uJ2wMUzXNDbCk65uJ5vQtjMlJXYle_2lxd28P2YlK8=s180-rw",
  },
  {
    App_Name: "Sachin Dhawale's Maths and Reasoning Academy",
    City: "Pune",
    State: "Maharashtra",
    App_Link: "https://edushield.page.link/dAcd",
    Download: 39195,
    Logo: "https://play-lh.googleusercontent.com/6CdvaxAbW84v-XSyzqHtwd1oIDsvhM0vpDtNFkEtCzkf_pJnpQPRB0yteJbgS-ARGH0=s180-rw",
  },
  {
    App_Name: "IGNITE Online Academy",
    City: "Pune",
    State: "Maharashtra",
    App_Link: "https://eduiron.page.link/x46D",
    Download: 33726,
    Logo: "https://play-lh.googleusercontent.com/DRoVI5_DXU00F8kgzEZtQaHI129J3ZUvYJ1XPJxRXgmjhRqn3zK6ymlfAFdrc-AfWKT1=s180-rw",
  },
  {
    App_Name: "Shikhar Educare",
    City: "Amravati",
    State: "Maharashtra",
    App_Link: "https://eduthor.page.link/3hZW",
    Download: 1934,
    Logo: "https://play-lh.googleusercontent.com/IjtQGIexRgJVcF0UUEuTT4MzzeBti9SR2tGoZml8YHB69gSeFfklgX8ZNC0JrMdd14Xe=s180-rw",
  },
  {
    App_Name: "Herbert app",
    City: "Manipur",
    State: "Manipur",
    App_Link: "https://edushield.page.link/foCq",
    Download: 2310,
    Logo: "https://play-lh.googleusercontent.com/RXiqy6nCPz_6iCqLB4cJtPn19BVtoBlXS9szw0NfF9TXBRS53kcdnGSfG11fbUBg0FAX=s180-rw",
  },
  {
    App_Name: "Lairiklaishu",
    City: "Imphal",
    State: "Manipur",
    App_Link: "https://aalicia.page.link/7LrR",
    Download: 21,
    Logo: "https://play-lh.googleusercontent.com/2fLHXdvftNrSv0TpiQkryl5CLZiCFNx1IbLXmWGWkN-uAs_387VAD6iCaOb8TJBoFAg=s180-rw",
  },
  {
    App_Name: "RADinstitute",
    City: "Imphal West",
    State: "Manipur",
    App_Link: "https://hodor.page.link/QkTL",
    Download: 169,
    Logo: "https://play-lh.googleusercontent.com/dzsuxFzqql-euIaVavv4N_IKCV8FUjUNxm5fMsd9iLt02Ps2MNbwF12yFKD4rY0_iMoV=s180-rw",
  },
  {
    App_Name: "Dr Pronob Academy of Dental Sciences",
    City: "Shillong",
    State: "Meghalaya",
    App_Link: "https://varys.page.link/sxDY",
    Download: 200,
    Logo: "https://play-lh.googleusercontent.com/oEsYaNrOfn2_2J-ZYtVb8HyMK-iLyPsjSQY4C0IutSbC-t8psWCkA7R9RTuHx-8-I3kh=s180-rw",
  },
  {
    App_Name: "LAMIN COACHING PLATFORM",
    City: "Shillong",
    State: "Meghalaya",
    App_Link: "https://aalexis.page.link/d3j7",
    Download: 135,
    Logo: "https://play-lh.googleusercontent.com/ZurUhbhD_ilPp9ihcYNt9wD_cl7ATIhZZDN-lUPZ6x0yFjcmjkYEAULezM0M9iVedU8=s180-rw",
  },
  {
    App_Name: "Civil Edu Tech",
    City: "West Garo Hills",
    State: "Meghalaya",
    App_Link: "https://sansa.page.link/r7c8",
    Download: 49,
    Logo: "https://play-lh.googleusercontent.com/VhssGf5_61GzBz3NPO-PjVTg_Mhk63uxNNSoNyENm4yZwU50Xd9effoDn-kfvT9pIQ=s180-rw",
  },
  {
    App_Name: "Evans Academy",
    City: "Koraput",
    State: "Mizoram",
    App_Link: "https://skyarya.page.link/pmpz",
    Download: 227,
    Logo: "https://play-lh.googleusercontent.com/SNJIuCaSusea-HL34GQa4rh0gAlHwlj_UJSuVSXWzg2IcZzxbeqqSjOjsmSk7H-VKLw=s180-rw",
  },
  {
    App_Name: "Chase Academy",
    City: "Aizawl",
    State: "Mizoram",
    App_Link: "https://edushield.page.link/sVzR",
    Download: 235,
    Logo: "https://play-lh.googleusercontent.com/Te2YUX5IGadAUTNk-a32FsClyObfBQ0H3BWpX3y1-mQ39n2S9f3VCZnN_EgBmiYE1bah=s180-rw",
  },
  {
    App_Name: "Edu Imperial",
    City: "Dimapur",
    State: "Nagaland",
    App_Link: "https://edukevin.page.link/izL3",
    Download: 612,
    Logo: "https://play-lh.googleusercontent.com/NUpteYrn-Ocu2ph9iik4-elQ86RuDPRrz0mRUQuh1YLXGxvUG2fyd8yejkZOveLFg8B7=s180-rw",
  },
  {
    App_Name: "PRAMOD CLASSES",
    City: "Bhubaneswar",
    State: "Orissa",
    App_Link: "https://edustan.page.link/J7cT",
    Download: 5600,
    Logo: "https://play-lh.googleusercontent.com/AdFMTE-M-GpU8sFpzAhlXTV3AR5iGz9shDHFfvv1TmZV1Ow5jNNswzaOVYERLshmqZM=s180-rw",
  },
  {
    App_Name: "Exam Corner",
    City: "Bhubaneshwar",
    State: "Orissa",
    App_Link: "https://treeloki.page.link/vLMb",
    Download: 2493,
    Logo: "https://play-lh.googleusercontent.com/XcvnnYjm6437rUmnqpyXQt6jQieB4MbpHV7U9CJKwOK-inCOpBDES5hwdbDsaWB-ynw=s180-rw",
  },
  {
    App_Name: "ODISHA JOB ACADEMY",
    City: "Bhubaneswar",
    State: "Orissa",
    App_Link: "https://khal.page.link/iho8",
    Download: 967,
    Logo: "https://play-lh.googleusercontent.com/t6by44ME37xss9A967MiLx487ew5CHqPJibZP8pxPuBqkrCkWLyYc6Bm-fcnaPILCg=s180-rw",
  },
  {
    App_Name: "SAAVAL ABOHAR",
    City: "Firozpur",
    State: "Punjab",
    App_Link: "https://aalexis.page.link/QExc",
    Download: 6310,
    Logo: "https://play-lh.googleusercontent.com/2kceKmXOyxK7ON90J1iSTA1m4AfB-bXZFC9VQemV7sHUn1C0fXGunR1IM_F0nl0NvCs=s180-rw",
  },
  {
    App_Name: "Maths Made Easy",
    City: "Fazilka",
    State: "Punjab",
    App_Link: "https://clp.page.link/9oz2",
    Download: 11426,
    Logo: "https://play-lh.googleusercontent.com/cij60vh_lEdbBprZNVYa32zeOckODwfR5BFRgda2JHeHLQeNSvwqPBwy8GnWJZM6ZvE=s180-rw",
  },
  {
    App_Name: "Ielts coaching",
    City: "Moga",
    State: "Punjab",
    App_Link: "https://clp.page.link/uUub",
    Download: 40004,
    Logo: "https://play-lh.googleusercontent.com/soGvzBv98tfkDb3mCSuEaKRGWkJFFLPSGMMmXqag1mffkNP410f3vtERPTvorFx774E=s180-rw",
  },
  {
    App_Name: "VIJAYEE BHAV",
    City: "Jaipur",
    State: "Rajasthan",
    App_Link: "https://edunick.page.link/N4Ge",
    Download: 12003,
    Logo: "https://play-lh.googleusercontent.com/gJUsklCoDItgkXDRatVlxDWyHRS7x_mtHcSHWZobos-DnrMlaWz8ia7nbzyzLA9vz8s=s180-rw",
  },
  {
    App_Name: "Sanskrit Safalyam",
    City: "Jaipur",
    State: "Rajasthan",
    App_Link: "https://edustan.page.link/X3q2",
    Download: 201929,
    Logo: "https://play-lh.googleusercontent.com/zNMbLEkuEQI1G_lwCCzlgYZoK3IbeAGqvOMwebOYqhQN0qDpb9HNsCCuEzDhkkE7b1E=s180-rw",
  },
  {
    App_Name: "SARASWATI DHAAM Institute",
    City: "Jaipur",
    State: "Rajasthan",
    App_Link: "https://cbran.page.link/yvdC",
    Download: 6702,
    Logo: "https://play-lh.googleusercontent.com/wQpZ3GcN8qsRioDq6skguvSYbJb2fDsg3u1qfziTfk1YkZARDCQstohsjChyid9rUg=s180-rw",
  },
  {
    App_Name: "Mentors Private Tutorial For All",
    City: "SINGTAM",
    State: "Sikkim",
    App_Link: "https://tarly.page.link/xpGm",
    Download: 105,
    Logo: "https://play-lh.googleusercontent.com/3eQ0pdTwvpjlXvsDxh36tpUiO5LmP2yHC8SQnvG0tgYvQ_WRLwJVLiiRqmoS1WICPbCG=s180-rw",
  },
  {
    App_Name: "Athiyaman Team",
    City: "Coimbatore",
    State: "Tamil Nadu",
    App_Link: "https://clp.page.link/4Gwa",
    Download: 73301,
    Logo: "https://play-lh.googleusercontent.com/ytVILuTanEjQkiZ0hn3RPQuPJlZqQ21nyQJmgdjaiGh34FJe6MhjK_FroxgDH4S4mcQh=s180-rw",
  },
  {
    App_Name: "Paadarivom Padipparivom (Learn2Learn)",
    City: "Chennai",
    State: "Tamil Nadu",
    App_Link: "https://eduiron.page.link/VdKx",
    Download: 1454,
    Logo: "https://play-lh.googleusercontent.com/QaG3gQvbTWkss_j7lKUNYJl6xsCymaUktM7923v2qW0hJiPtsrvKj8otf50d4YrYUTc=s180-rw",
  },
  {
    App_Name: "PRIME POSTAL ACADEMY",
    City: "Erode",
    State: "Tamil Nadu",
    App_Link: "https://skyarya.page.link/3o5Y",
    Download: 2387,
    Logo: "https://play-lh.googleusercontent.com/z6sPjAAd-kaeCBqOdIWhl6JLI0n7SlIiRQxGO-UdnWm0ps89pNBYWbAeuMWpa8wz2Q=s180-rw",
  },
  {
    App_Name: "SAIMEDHA.IN",
    City: "Hyderabad",
    State: "Telangana",
    App_Link: "https://edushield.page.link/eLXa",
    Download: 46286,
    Logo: "https://play-lh.googleusercontent.com/onVxGlJOuAoTlPCtxdA77kqoC_EINmWzdbCnopJeKPcKD4LZK3CN_ZRWVegVcD2-8ME=s180-rw",
  },
  {
    App_Name: "CHANDAN LOGICS",
    City: "Hyderabad",
    State: "Telangana",
    App_Link: "https://clp.page.link/DegF",
    Download: 109453,
    Logo: "https://play-lh.googleusercontent.com/0KB7Gnrmlac-ti54_nROHeKxrxb8eyEkTGNcydPe9Y-WAi-atppxR6PvafTxqsLa6gw=s180-rw",
  },
  {
    App_Name: "Right choice academy",
    City: "Hyderabad",
    State: "Telangana",
    App_Link: "https://clp.page.link/ef6H",
    Download: 51662,
    Logo: "https://play-lh.googleusercontent.com/e2OfjR7QF3zMP3nwqclrUaz8ILJIir73Jre5sTH6JfW1X7lf2wcJFLxFqKmXlD_mWw=s180-rw",
  },
  {
    App_Name: "illustraca.com",
    City: "Agartala",
    State: "Tripura",
    App_Link: "https://clpgroot.page.link/LZFP",
    Download: 329,
    Logo: "https://play-lh.googleusercontent.com/pmbRuCPTfUfFe6mcBd52ETts3NjlUV4q5lx9aYGreq14hJz3ZHYHBn0uhMiYuCorkk9O=s180-rw",
  },
  {
    App_Name: "ND STUDY",
    City: "Unakoti",
    State: "Tripura",
    App_Link: "https://edukevin.page.link/rtHi",
    Download: 603,
    Logo: "https://play-lh.googleusercontent.com/6QQ18VwXb7aDGGg4gjtlS60hsqDh2Y_2Re6h96XRxOWEyEcmr77u-p_6PpHkFHnD3Q=s180-rw",
  },
  {
    App_Name: "ISTCEC",
    City: "North Tripura",
    State: "Tripura",
    App_Link: "https://edunick.page.link/qpGw",
    Download: 146,
    Logo: "https://play-lh.googleusercontent.com/4vmz1jaQrbMyec3251JuJjbUgdrQ1iKcRqSEG37_GbNSK1MrbAqJ3uBUyE_vDfi-SA=s180-rw",
  },
  {
    App_Name: "GDC CLASSES",
    City: "Bilaspur",
    State: "Uttar Pradesh",
    App_Link: "https://edustan.page.link/XdJm",
    Download: 75310,
    Logo: "https://play-lh.googleusercontent.com/JZyYjx2uzzfBWE3LBvEoD3pRsIQSwVgSvG2U2KRNTvXRgAz-jTY3xjB0VqAiwWjX1zE=s180-rw",
  },
  {
    App_Name: "TARGETwithAlok",
    City: "Prayagraj",
    State: "Uttar Pradesh",
    App_Link: "https://aalexis.page.link/iok3",
    Download: 80296,
    Logo: "https://play-lh.googleusercontent.com/wBfFeSsVDQrJrE-x_9GVRTseL85QamJfCHOSa5PkrGpFOA1RLQTAIWpztFjA0VZ3VoWR=s180-rw",
  },
  {
    App_Name: "RKG Institute by CA Parag Gupta",
    City: "Noida",
    State: "Uttar Pradesh",
    App_Link: "https://goo.gl/gHL4gw",
    Download: 33723,
    Logo: "https://play-lh.googleusercontent.com/1BOS0yOPURwHrpJH75sBhAZ7SyBTA6ClS3Fd_wjNrO7t0wyNUOEDbrMow5oEyNr66w=s180-rw",
  },
  {
    App_Name: "Engineering Academy Dehradun Paid Course",
    City: "Dehradun",
    State: "Uttarakhand",
    App_Link: "https://clp.page.link/kGHt",
    Download: 80020,
    Logo: "https://play-lh.googleusercontent.com/481mDGbU7x76XhfJvE5l9oid7P9WqIWRJkNOoLj8Sw5tmp7CMhxJDq_AUphDbsJWinE=s180-rw",
  },
  {
    App_Name: "Divas Gupta",
    City: "Dehradun",
    State: "Uttarakhand",
    App_Link: "https://clp.page.link/B8Q1",
    Download: 8555,
    Logo: "https://play-lh.googleusercontent.com/BWBd-77AhQO25Dfey_3XPGHoHfAVKtEg5q-ELLlUw-AnyRslX0cESGwZxmM7nfKToCE=s180-rw",
  },
  {
    App_Name: "Smartious Commerce Academy by CA Piyush Gupta",
    City: "Haridwar",
    State: "Uttarakhand",
    App_Link: "https://clp.page.link/Pgcc",
    Download: 33371,
    Logo: "https://play-lh.googleusercontent.com/A37Mbf22PbrgXeKReo54aOTWQg4pBOH-X5QcpDxZC9LcLH7QwV4vXXZaR_83mB9r11I=s180-rw",
  },
  {
    App_Name: "MH EDUCATION LAB",
    City: "Duttapukar",
    State: "West Bengal",
    App_Link: "https://eduiron.page.link/zF2u",
    Download: 5070,
    Logo: "https://play-lh.googleusercontent.com/rzzNbdvhCLb2bSLGnLPrtUcv0XZEtWl7pvdQJBmaVKjqCX3ukWnmX_1b4OLS3Fq1NTN6=s180-rw",
  },
  {
    App_Name: "WBSC MADE EASY",
    City: "Kolkata",
    State: "West Bengal",
    App_Link: "https://eduiron.page.link/eSbG",
    Download: 4342,
    Logo: "https://play-lh.googleusercontent.com/vo_E5D5DPuw7VSaeMUcTAjyYtmp18LUGINDAwljaBgTrcFVmtZxnL9AQ6SBcSvy3faUX=s180-rw",
  },
  {
    App_Name: "Dr.Pratim's Academy",
    City: "Kolkata",
    State: "West Bengal",
    App_Link: "https://eduthanos.page.link/ejaS",
    Download: 974,
    Logo: "https://play-lh.googleusercontent.com/DnyT04hOxdTfkHVD7NdANFpQjkp0p7DUC6mgaBEwjjcjjOOVJOcxJlTYuYtxjCr1R2h8=s180-rw",
  },
];
