
export const conversationsEG  = {
  EN : ["Teacherpreneur","Solopreneur","Videopreneur","Contentpreneur"],
  TE : ["టీచర్ ప్రెన్యూర్","సోలో ప్రెన్యూర్","వీడియో ప్రెన్యూర్","కంటెంట్ ప్రెన్యూర్"],
  KA : ["ಶಿಕ್ಷಕಪ್ರೇನಿಯರ್","ಸೋಲೋಪ್ರೆನಿಯರ್","ವಿಡಿಯೋಪ್ರಿನಿಯರ್","ಕಂಟೆಂಟ್ಪ್ರೆನಿಯರ್"],
  MA : ["ടീച്ചർപ്രണർ","സോളോപ്രണർ","വീഡിയോപ്രണർ","ഉള്ളടക്കപ്രിയർ"],
  TA : ["ஆசிரியர் முனைவர்","சோலோபிரீனர்","வீடியோ பிரீனியர்","கன்டெண்ட்ப்ரீனர்"]

};

export const ButtonTexts = {
  EN : 'English',
  TE : 'Telugu',
  KA : 'Kannada',
  MA : 'Malayalam',
  TA : 'Tamil'
}

export const conversationsENG = {
  android: {
    type: "androidScreen",
    id: "android",
    msg: "",
    next: "student-count",
  },
  name: {
    type: "radio",
    id: "name",
    key: "isOnlineTutor",
    question: {
      msg: "Are you ready? Take the first step here",
      options: [
        {
          value: "I run a coaching center",
          next: "coaching-number-intro",
          key: "coaching-center",
        },
        // {
        //   value: "I want to launch an online course",
        //   next: "online-course-number",
        //   key: "online-course",
        // },

        {
          value: "I am a YouTuber",
          next: "youtuber-number-intro",
          key: "youtuber",
        },
        {
          value: "I teach in a school",
          next: "teacher-consent",
          key: "school-teacher",
        },
        {
          value: "I am a student",
          next: "student-teacher-number",
          key: "student",
        },
      ],
    },
    next: "coaching-name",
    isShown: true,
  },
  "coaching-name-intro": {
    type: "simple",
    id: "coaching-name-intro",
    msg: "It’s great that you have taken this responsibility of changing the world through your knowledge & wisdom.",
    next: "coaching-name",
    isShown: false,
  },
  "coaching-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "coaching-name",
    question: {
      msg: "What is the name of your coaching institute?",
      options: [],
    },
    next: "coaching-size-intro",
    isShown: false,
    switchScreen: true,
  },
  "coaching-number-intro": {
    type: "simple",
    id: "coaching-number-intro",
    msg: "You're just a few steps away from becoming a teacherpreneur!",
    next: "coaching-number-intro-2",
    isShown: false,
  },
  "coaching-number-intro-2": {
    type: "simple",
    id: "coaching-number-intro-2",
    msg: "You can manage your offline coaching and grow your online business.",
    next: "coaching-number",
    isShown: false,
  },

  // "coaching-number-intro-2": {
  //   type: "simple",
  //   id: "coaching-number-intro-2",
  //   msg: "We need a few more details to help you with the best tools you need",
  //   next: "coaching-number",
  //   isShown: false,
  // },
  "coaching-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "coaching-number",
    question: {
      msg: "What’s your contact number?",
      options: [],
    },
    next: "coaching-name",
    isSubmitted: false,
  },
  "online-course-number-intro": {
    type: "simple",
    id: "online-course-number-intro",
    msg: "You're just a few steps away from becoming a solopreneur!",
    next: "online-course-number",
    isShown: false,
  },
  "online-course-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "online-course-number",
    question: {
      msg: "What’s your contact number?",
      options: [],
    },
    next: "online-course-name",
    isSubmitted: false,
  },

  "online-course-name": {
    type: "input",
    subtype: "text",
    key: "instituteName",
    id: "online-course-name",
    question: {
      msg: "What's your name?",
      options: [],
    },
    next: "online-course-is-creator",
    isSubmitted: false,
    switchScreen: true,
  },

  "online-course-is-creator": {
    type: "radio",
    id: "online-course-is-creator",
    key: "online-course-is-creator",
    question: {
      msg: "Do you have a YouTube channel or an Instagram handle?",
      options: [
        {
          value: "Yes",
          key: "1",
          next: "online-course-subscribers",
        },
        {
          value: "No",
          key: "0",
          next: "youtuber-categories",
        },
      ],
    },
    next: "online-course-subscribers",
    isShown: false,
  },

  "online-course-subscribers": {
    type: "radio",
    id: "online-course-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "How many subscribers/followers do you have?",
      options: [
        {
          value: "Less than 1,000",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "More than 10,000",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "coaching-size-intro": {
    type: "simple",
    id: "coaching-size-intro",
    msg: "We need a few more details about your coaching",
    next: "coaching-size",
    isShown: false,
  },

  "coaching-size": {
    type: "radio",
    id: "coaching-size",
    key: "numberOfStudents",
    question: {
      msg: "How many students are you teaching?",
      options: [
        {
          value: "0 - 50 students",
          key: "0-100",
          next: "0-100-intro",
        },
        {
          value: "50 - 250 students",
          key: "100-250",
          next: "100-250-intro",
        },
        { value: "250+ students", key: "250+", next: "250-intro" },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },

  "0-100-intro": {
    type: "simple",
    id: "0-100-intro",
    msg: "Great! Educators with up to 50 students make nearly Rs 10 lakh per year at Classplus by selling courses online & taking live classes",
    next: "isRecordedContent",
    isShown: false,
  },
  "100-250-intro": {
    type: "simple",
    id: "100-250-intro",
    msg: "Awesome! You have a chance to make up to Rs 30 lakh in a year just like others who are using Classplus by selling courses online & taking live classes",
    next: "isRecordedContent",
    isShown: false,
  },
  "250-intro": {
    type: "simple",
    id: "250-intro",
    msg: "Wow! Get ready to increase your income upto Rs 60 lakh in a year with Classplus by selling courses online & taking live classes",
    next: "isRecordedContent",
    isShown: false,
  },
  "app-features": {
    type: "multiSelect",
    id: "app-features",
    key: "features",
    question: {
      msg: "How do you want to use the app?",
      options: [
        {
          value: "Sell online courses",
          key: "sell-courses",
        },
        {
          value: "Take live classes",
          key: "live-classes",
        },
        { value: "Conduct online test", key: "online-test" },
        { value: "Batch management", key: "fee-attendance" },
      ],
    },
    next: "app-features-intro-2",
    isShown: false,
    isSkippable: true,
  },

  "coaching-categories": {
    type: "dropdown",
    id: "coaching-categories",
    key: "skillCategory",
    question: {
      msg: "What subjects do you teach?", // new content
      options: [
        { value: "School Board Exams", key: "school-board-exams" },
        {
          value: "College/University Entrance Exams",
          key: "college-university-entrance-exams",
        },
        {
          value: "College/University Semester Exams",
          key: "college-university-semester-exams",
        },
        { value: "Govt Job Exams", key: "govt-job-exams" },
        { value: "CA, CS, CMA, CFA Courses", key: "professional-courses" },
        { value: "Non Academic Courses", key: "non-academic-courses" },
        { value: "Others", key: "others" },
      ],
    },
    next: "learnGrowthTips",
    isShown: false,
  },

  isRecordedContent: {
    type: "radio",
    id: "isRecordedContent",
    key: "isRecordedContent",
    question: {
      msg: "Do you have recorded lectures to start your online coaching?",
      options: [
        {
          value: "Yes, I have",
          key: 1,
          next: "isRecordedYes",
        },
        {
          value: "No, I will create it",
          key: 0,
          next: "isRecordedNo",
        },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },
  isRecordedYes: {
    type: "simple",
    id: "isRecordedYes",
    msg: "Awesome! Do you know you can sell these lectures and make more money through your app",
    next: "coaching-categories",
    isShown: false,
  },
  isRecordedNo: {
    type: "simple",
    id: "isRecordedNo",
    msg: "No worries, it’s very easy to create videos on our app. All you need is the content!",
    next: "coaching-categories",
    isShown: false,
  },
  "coaching-email": {
    type: "input",
    subtype: "text",
    id: "coaching-email",
    key: "email",
    question: {
      msg: "Please enter your email address.",
      options: [],
    },
    next: "coaching-categories",
    isShown: false,
  },
  state: {
    type: "dropdown",
    id: "state",
    key: "state",
    question: {
      msg: "Select your state to know the leading coachings in your area who are using Classplus",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "state-youtubers": {
    type: "dropdown",
    id: "state-youtubers",
    key: "state",
    question: {
      msg: "Select your state to know the content creators in your area who are using Classplus",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "youtuber-categories-intro": {
    type: "simple",
    id: "youtuber-categories-intro",
    msg: "Awesome! You are on the right track to become India’s leading content creator",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-categories": {
    type: "dropdown",
    id: "youtuber-categories",
    key: "skillCategory",
    question: {
      msg: "What kind of content do you create?", // new content
      options: [
        { value: "School Board Exams", key: "school-board-exams" },
        {
          value: "College/University Entrance Exams",
          key: "college-university-entrance-exams",
        },
        {
          value: "College/University Semester Exams",
          key: "college-university-semester-exams",
        },
        { value: "Govt Job Exams", key: "govt-job-exams" },
        { value: "CA, CS, CMA, CFA Courses", key: "professional-courses" },
        // { value: "Music", key: "music" },
        {
          value: "Personality Development/Business Coach",
          key: "personality-development",
        },
        { value: "Stock and Crypto Trading", key: "stock-trading" },
        // {value: "Photography", key: "photography" },
        // { value: "Language Training", key: "languages" },
        { value: "Fitness", key: "fitness" },
        { value: "Software Training", key: "software-training" },
        { value: "Others", key: "others" },
      ],
    },
    isShown: false,
    next: "learnGrowthTips",
  },
  "youtuber-name": {
    type: "input",
    subtype: "text",
    id: "youtuber-name",
    key: "instituteName",
    question: {
      msg: "Please tell me the name of your YouTube channel or Instagram handle",
      options: [],
    },
    next: "youtuber-subscribers",
    isShown: false,
    switchScreen: true,
  },
  "youtuber-number": {
    type: "input",
    subtype: "number",
    id: "youtuber-number",
    key: "phoneNumber",
    question: {
      msg: "Please share your contact number",
      options: [],
    },
    next: "youtuber-name",
    isSubmitted: false,
  },
  "youtuber-subscribers": {
    type: "radio",
    id: "youtuber-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "How many subscribers/followers do you have? ",
      options: [
        {
          value: "Less than 1,000",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "More than 10,000",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "less-than-1000-intro": {
    type: "simple",
    id: "less-than-1000-intro",
    msg: "Great! Content creators with up to 1000 subscribers/followers make nearly Rs 10 lakh per year at Classplus",
    next: "youtuber-categories",
    isShown: false,
  },
  "1000-10000-intro": {
    type: "simple",
    id: "1000-10000-intro",
    msg: "Awesome! You have a chance to make up to Rs 30 lakh in a year just like others who are using Classplus",
    next: "youtuber-categories",
    isShown: false,
  },
  "10000-intro": {
    type: "simple",
    id: "10000-intro",
    msg: "Wow! Get ready to increase your income up to Rs 60 lakh in a year with Classplus",
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-email": {
    type: "input",
    subtype: "email",
    id: "youtuber-email",
    key: "email",
    question: {
      msg: "Please enter your email address.",
      options: [],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-number-intro": {
    type: "simple",
    id: "youtuber-number-intro",
    msg: "You're just a few steps away from becoming a contentpreneur!",
    next: "youtuber-number-intro-2",
    isShown: false,
  },
  "youtuber-number-intro-2": {
    type: "simple",
    id: "youtuber-number-intro-2",
    msg: "There are more than 50,000 YouTube/Instagram content creators who use Classplus and trippled their income in 3 months.",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-name-exit": {
    type: "simple",
    id: "youtuber-name-exit",
    msg: "You're just a few steps away from becoming a contentpreneur!",
    next: "youtuber-number",
    isShown: false,
  },

  "teacher-consent": {
    type: "radio",
    id: "teacher-consent",

    question: {
      msg: "Are you looking to start your own coaching business and become a teacherprenuer?",
      options: [
        {
          value: "Yes, Definitely!",
          key: "yes",
          next: "teacher-name-intro",
        },
        {
          value: "I am not sure how to start :(",
          key: "no",
          next: "teacher-name-negative-intro",
        },
      ],
    },
    next: "teacher-name",
    isShown: false,
  },
  "teacher-name-intro": {
    type: "simple",
    id: "teacher-name-intro",
    msg: "That’s great! We need a few more details to guide you with the new online journey!",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name-negative-intro": {
    type: "simple",
    id: "teacher-name-negative-intro",
    msg: "No worries! We will guide you with your first steps of starting online",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "teacher-name",
    question: {
      msg: "Please share your name",
      options: [],
    },
    next: "teacher-click-more",
    isShown: false,
    switchScreen: true,
  },

  "teacher-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "teacher-number",
    question: {
      msg: "Please share your contact number",
      options: [],
    },
    next: "teacher-name",
    isSubmitted: false,
  },
  "teacher-click-more": {
    type: "radio",
    id: "teacher-click-more",
    question: {
      msg: "Do you know with Classplus you can grow your business online and become a national brand easily.",
      options: [
        {
          value: "Tell me more",
          key: "yes",
        },
      ],
    },
    next: "teacher-curious",
  },
  "teacher-curious": {
    type: "radio",
    id: "teacher-curious",
    question: {
      msg: "With Classplus you can take online classes, sell recorded lectures, share assignments and much more!",
      options: [
        {
          value: "I am curious to know more about this",
          key: "yes",
        },
      ],
    },
    next: "teacher-recorded-info",
  },
  "teacher-recorded-info": {
    type: "simple",
    id: "teacher-recorded-info",
    msg: "Teachers with recorded lectures are able to earn extra income by selling online courses",
    next: "coaching-categories",
    isShown: false,
  },
  student: {
    type: "radio",
    id: "student",
    key: "student-consent",
    question: {
      msg: "Hey, we are delighted to see you here. Does your coaching institute use a mobile app to teach online?",
      options: [
        {
          value: "No, they don't",
          key: "no",
          next: "share-classplus",
        },
        {
          value: "Yes, they do",
          key: "yes",
          next: "share-classplus-yes",
        },
      ],
    },
    next: "share-classplus",
  },

  "share-classplus": {
    type: "simple",
    id: "share-classplus",
    msg: "Ask them to try Classplus and stand a chance to win Rs 500 Amazon Voucher ",
    next: "student-teacher-number",
    isShown: false,
  },

  "student-teacher-number": {
    type: "input",
    subtype: "number",
    id: "student-teacher-number",
    key: "phoneNumber",
    question: {
      msg: "Enter your number here",
      options: [],
    },
    next: "student-teacher-name",
    isSubmitted: false,
  },
  "student-teacher-name": {
    type: "input",
    subtype: "text",
    id: "student-teacher-name",
    key: "instituteName",
    question: {
      msg: "Please share your name",
      options: [],
    },
    next: "thanku",
    isSubmitted: false,
  },
  thanku: {
    type: "radio",
    id: "thanku",
    key: "thanku",
    question: {
      msg: "Thank you!",
      options: [],
    },
    next: "end",
    isShown: false,
    isSkippable: true,
  },

  "share-classplus-yes": {
    type: "simple",
    id: "share-classplus-yes",
    msg: "That's great to hear! Ask them to try Classplus and stand a chance to win Rs 500 Amazon Voucher",
    next: "student-teacher-number",
    isShown: false,
  },

  learnGrowthTips: {
    type: "radio",
    id: "learnGrowthTips",
    key: "learnGrowthTips",
    question: {
      msg: "To stay updated with the latest news & offers",
      options: [
        {
          value: "Join our WhatsApp Community",
          key: 1,
          next: "end",
        },
        // {
        //   value: "No",
        //   key: 0,
        //   next: "end",
        // },
      ],
    },
    next: "end",
    isShown: false,
    isSkippable: true,
  },
};

export const conversationsKA ={
  android: {
    type: "androidScreen",
    id: "android",
    msg: "",
    next: "student-count",
  },
  name: {
    type: "radio",
    id: "name",
    key: "isOnlineTutor",
    question: {
      msg: "ನೀವು ಸಿದ್ಧರಿದ್ದೀರಾ? ಇಲ್ಲಿ ಮೊದಲ ಹೆಜ್ಜೆ ಇರಿಸಿ",
      options: [
        {
          value: " ನಾನು ಕೋಚಿಂಗ್ ಸೆಂಟರ್ ನಡೆಸುತ್ತಿದ್ದೇನೆ ",
          next: "coaching-number-intro",
          key: "coaching-center",
        },
        // {
        //   value: "ನಾನು ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸಲು ಬಯಸುತ್ತೇನೆ",
        //   next: "online-course-number",
        //   key: "online-course",
        // },

        {
          value: "ನಾನು ವಿಷಯ ರಚನೆಕಾರ",
          next: "youtuber-number-intro",
          key: "youtuber",
        },
        {
          value: "ನಾನು ಶಾಲೆಯಲ್ಲಿ ಕಲಿಸುತ್ತೇನೆ",
          next: "teacher-consent",
          key: "school-teacher",
        },
        {
          value: "ನಾನು ಶಿಕ್ಷಕ/ಸೃಷ್ಟಿಕರ್ತನಲ್ಲ",
          next: "student-teacher-number",
          key: "student",
        },
      ],
    },
    next: "coaching-name",
    isShown: true,
  },
  "coaching-name-intro": {
    type: "simple",
    id: "coaching-name-intro",
    msg: "ನಿಮ್ಮ ಜ್ಞಾನ ಮತ್ತು ಬುದ್ಧಿವಂತಿಕೆಯ ಮೂಲಕ ಜಗತ್ತನ್ನು ಬದಲಾಯಿಸುವ ಈ ಜವಾಬ್ದಾರಿಯನ್ನು ನೀವು ತೆಗೆದುಕೊಂಡಿರುವುದು ಅದ್ಭುತವಾಗಿದೆ.",
    next: "coaching-name",
    isShown: false,
  },
  "coaching-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "coaching-name",
    question: {
      msg: "ನಿಮ್ಮ ಕೋಚಿಂಗ್ ಸಂಸ್ಥೆಯ ಹೆಸರೇನು?",
      options: [],
    },
    next: "coaching-size-intro",
    isShown: false,
    switchScreen: true,
  },
  "coaching-number-intro": {
    type: "simple",
    id: "coaching-number-intro",
    msg: "ನೀವು ಶಿಕ್ಷಕರಾಗಲು ಕೆಲವೇ ಹೆಜ್ಜೆಗಳ ದೂರದಲ್ಲಿದ್ದೀರಿ!",
    next: "coaching-number-intro-2",
    isShown: false,
  },
  "coaching-number-intro-2": {
    type: "simple",
    id: "coaching-number-intro-2",
    msg: "ನಿಮ್ಮ ಆಫ್‌ಲೈನ್ ಕೋಚಿಂಗ್ ಅನ್ನು ನೀವು ನಿರ್ವಹಿಸಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ವ್ಯಾಪಾರವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಬಹುದು.",
    next: "coaching-number",
    isShown: false,
  },

  // "coaching-number-intro-2": {
  //   type: "simple",
  //   id: "coaching-number-intro-2",
  //   msg: "ನಿಮಗೆ ಅಗತ್ಯವಿರುವ ಉತ್ತಮ ಪರಿಕರಗಳೊಂದಿಗೆ ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ನಮಗೆ ಇನ್ನೂ ಕೆಲವು ವಿವರಗಳ ಅಗತ್ಯವಿದೆ",
  //   next: "coaching-number",
  //   isShown: false,
  // },
  "coaching-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "coaching-number",
    question: {
      msg: "ನಿಮ್ಮ ಸಂಪರ್ಕ ಸಂಖ್ಯೆ ಏನು?",
      options: [],
    },
    next: "coaching-name",
    isSubmitted: false,
  },
  "online-course-number-intro": {
    type: "simple",
    id: "online-course-number-intro",
    msg: "ನೀವು ಸೋಲೋಪ್ರೇನಿಯರ್ ಆಗಲು ಕೆಲವೇ ಹೆಜ್ಜೆಗಳ ದೂರದಲ್ಲಿರುವಿರಿ!",
    next: "online-course-number",
    isShown: false,
  },
  "online-course-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "online-course-number",
    question: {
      msg: "ನಿಮ್ಮ ಸಂಪರ್ಕ ಸಂಖ್ಯೆ ಏನು?",
      options: [],
    },
    next: "online-course-name",
    isSubmitted: false,
  },

  "online-course-name": {
    type: "input",
    subtype: "text",
    key: "instituteName",
    id: "online-course-name",
    question: {
      msg: "ನಿಮ್ಮ ಹೆಸರುಯೇನು ?",
      options: [],
    },
    next: "online-course-is-creator",
    isSubmitted: false,
    switchScreen: true,
  },

  "online-course-is-creator": {
    type: "radio",
    id: "online-course-is-creator",
    key: "online-course-is-creator",
    question: {
      msg: "ನೀವು YouTube ಚಾನಲ್ ಅಥವಾ Instagram ಹ್ಯಾಂಡಲ್ ಅನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
      options: [
        {
          value: "ಹೌದು",
          key: "1",
          next: "online-course-subscribers",
        },
        {
          value: "ಇಲ್ಲ",
          key: "0",
          next: "youtuber-categories",
        },
      ],
    },
    next: "online-course-subscribers",
    isShown: false,
  },

  "online-course-subscribers": {
    type: "radio",
    id: "online-course-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "ನೀವು ಎಷ್ಟು ಚಂದಾದಾರರು/ಅನುಯಾಯಿಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ?",
      options: [
        {
          value: "1,000 ಕ್ಕಿಂತ ಕಡಿಮೆ",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10,000 ಕ್ಕಿಂತ ಹೆಚ್ಚು",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "coaching-size-intro": {
    type: "simple",
    id: "coaching-size-intro",
    msg: "ನಿಮ್ಮ ತರಬೇತಿಯ ಕುರಿತು ನಮಗೆ ಇನ್ನೂ ಕೆಲವು ವಿವರಗಳ ಅಗತ್ಯವಿದೆ",
    next: "coaching-size",
    isShown: false,
  },

  "coaching-size": {
    type: "radio",
    id: "coaching-size",
    key: "numberOfStudents",
    question: {
      msg: "ನೀವು ಎಷ್ಟು ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಪಾಠ ಮಾಡುತ್ತಿದ್ದೀರಿ?",
      options: [
        {
          value: "0 - 50 ವಿದ್ಯಾರ್ಥಿಗಳು",
          key: "0-100",
          next: "0-100-intro",
        },
        {
          value: "50 - 250 ವಿದ್ಯಾರ್ಥಿಗಳು",
          key: "100-250",
          next: "100-250-intro",
        },
        { value: "250+ ವಿದ್ಯಾರ್ಥಿಗಳು", key: "250+", next: "250-intro" },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },

  "0-100-intro": {
    type: "simple",
    id: "0-100-intro",
    msg: "ಶ್ರೇಷ್ಠ! 50 ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಹೊಂದಿರುವ ಶಿಕ್ಷಕರು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವ ಮೂಲಕ ಮತ್ತು ಲೈವ್ ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೂಲಕ ಕ್ಲಾಸ್‌ಪ್ಲಸ್‌ನಲ್ಲಿ ವರ್ಷಕ್ಕೆ ಸುಮಾರು 10 ಲಕ್ಷ ರೂ.",
    next: "isRecordedContent",
    isShown: false,
  },
  "100-250-intro": {
    type: "simple",
    id: "100-250-intro",
    msg: "ಅದ್ಭುತ! ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವ ಮೂಲಕ ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಅನ್ನು ಬಳಸುವ ಇತರರಂತೆ ನೀವು ವರ್ಷದಲ್ಲಿ 30 ಲಕ್ಷದವರೆಗೆ ಗಳಿಸುವ ಅವಕಾಶವನ್ನು ಹೊಂದಿದ್ದೀರಿ",
    next: "isRecordedContent",
    isShown: false,
  },
  "250-intro": {
    type: "simple",
    id: "250-intro",
    msg: "ಅದ್ಭುತ! ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವ ಮೂಲಕ ಮತ್ತು ಲೈವ್ ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವ ಮೂಲಕ ಕ್ಲಾಸ್‌ಪ್ಲಸ್‌ನೊಂದಿಗೆ ಒಂದು ವರ್ಷದಲ್ಲಿ ನಿಮ್ಮ ಆದಾಯವನ್ನು ರೂ 60 ಲಕ್ಷದವರೆಗೆ ಹೆಚ್ಚಿಸಲು ಸಿದ್ಧರಾಗಿ",
    next: "isRecordedContent",
    isShown: false,
  },
  "app-features": {
    type: "multiSelect",
    id: "app-features",
    key: "features",
    question: {
      msg: "ನೀವು ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಹೇಗೆ ಬಳಸಲು ಬಯಸುತ್ತೀರಿ?",
      options: [
        {
          value: "ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡಿ",
          key: "sell-courses",
        },
        {
          value: "ಲೈವ್ ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
          key: "live-classes",
        },
        { value: "ಆನ್‌ಲೈನ್ ಪರೀಕ್ಷೆಯನ್ನು ನಡೆಸುವುದು", key: "online-test" },
        { value: "ಬ್ಯಾಚ್ ನಿರ್ವಹಣೆ", key: "fee-attendance" },
      ],
    },
    next: "app-features-intro-2",
    isShown: false,
    isSkippable: true,
  },

  "coaching-categories": {
    type: "dropdown",
    id: "coaching-categories",
    key: "skillCategory",
    question: {
      msg: "ನೀವು ಯಾವ ವಿಷಯಗಳನ್ನು ಕಲಿಸುತ್ತೀರಿ?", // new content
      options: [
        { value: "ಸ್ಕೂಲ್ ಬೋರ್ಡ್ ಪರೀಕ್ಷೆಗಳು", key: "school-board-exams" },
        {
          value: "ಕಾಲೇಜು/ವಿಶ್ವವಿದ್ಯಾಲಯ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗಳು",
          key: "college-university-entrance-exams",
        },
        {
          value: "ಕಾಲೇಜು/ವಿಶ್ವವಿದ್ಯಾಲಯದ ಸೆಮಿಸ್ಟರ್ ಪರೀಕ್ಷೆಗಳು",
          key: "college-university-semester-exams",
        },
        { value: "ಸರ್ಕಾರಿ ಉದ್ಯೋಗ ಪರೀಕ್ಷೆಗಳು", key: "govt-job-exams" },
        { value: "CA, CS, CMA, CFA ಕೋರ್ಸ್‌ಗಳು", key: "professional-courses" },
        { value: "ಶೈಕ್ಷಣಿಕೇತರ ಕೋರ್ಸ್‌ಗಳು", key: "non-academic-courses" },
        { value: "ಇತರರು", key: "others" },
      ],
    },
    next: "learnGrowthTips",
    isShown: false,
  },

  isRecordedContent: {
    type: "radio",
    id: "isRecordedContent",
    key: "isRecordedContent",
    question: {
      msg: "ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ತರಬೇತಿಯನ್ನು ಪ್ರಾರಂಭಿಸಲು ನೀವು ರೆಕಾರ್ಡ್ ಮಾಡಿದ ಉಪನ್ಯಾಸಗಳನ್ನು ಹೊಂದಿದ್ದೀರಾ?",
      options: [
        {
          value: "ಹೌದು ನನ್ನೊಂದಿಗಿದೆ",
          key: 1,
          next: "isRecordedYes",
        },
        {
          value: "ಇಲ್ಲ, ನಾನು ಅದನ್ನು ರಚಿಸುತ್ತೇನೆ",
          key: 0,
          next: "isRecordedNo",
        },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },
  isRecordedYes: {
    type: "simple",
    id: "isRecordedYes",
    msg: "ಅದ್ಭುತ! ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಮೂಲಕ ನೀವು ಈ ಉಪನ್ಯಾಸಗಳನ್ನು ಮಾರಾಟ ಮಾಡಬಹುದು ಮತ್ತು ಹೆಚ್ಚಿನ ಹಣವನ್ನು ಗಳಿಸಬಹುದು ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆಯೇ",
    next: "coaching-categories",
    isShown: false,
  },
  isRecordedNo: {
    type: "simple",
    id: "isRecordedNo",
    msg: "ಚಿಂತಿಸಬೇಡಿ, ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ವೀಡಿಯೊಗಳನ್ನು ರಚಿಸುವುದು ತುಂಬಾ ಸುಲಭ. ನಿಮಗೆ ಬೇಕಾಗಿರುವುದು ವಿಷಯ ಮಾತ್ರ!",
    next: "coaching-categories",
    isShown: false,
  },
  "coaching-email": {
    type: "input",
    subtype: "text",
    id: "coaching-email",
    key: "email",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ.",
      options: [],
    },
    next: "coaching-categories",
    isShown: false,
  },
  state: {
    type: "dropdown",
    id: "state",
    key: "state",
    question: {
      msg: "ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಅನ್ನು ಬಳಸುತ್ತಿರುವ ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಪ್ರಮುಖ ತರಬೇತಿಗಳನ್ನು ತಿಳಿಯಲು ನಿಮ್ಮ ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "state-youtubers": {
    type: "dropdown",
    id: "state-youtubers",
    key: "state",
    question: {
      msg: "ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಅನ್ನು ಬಳಸುತ್ತಿರುವ ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿನ ವಿಷಯ ರಚನೆಕಾರರನ್ನು ತಿಳಿಯಲು ನಿಮ್ಮ ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "youtuber-categories-intro": {
    type: "simple",
    id: "youtuber-categories-intro",
    msg: "ಅದ್ಭುತ! ಭಾರತದ ಪ್ರಮುಖ ವಿಷಯ ರಚನೆಕಾರರಾಗಲು ನೀವು ಸರಿಯಾದ ಹಾದಿಯಲ್ಲಿದ್ದೀರಿ",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-categories": {
    type: "dropdown",
    id: "youtuber-categories",
    key: "skillCategory",
    question: {
      msg: "ನೀವು ಯಾವ ರೀತಿಯ ವಿಷಯವನ್ನು ರಚಿಸುತ್ತೀರಿ?", // new content
      options: [
        { value: "ಸ್ಕೂಲ್ ಬೋರ್ಡ್ ಪರೀಕ್ಷೆಗಳು", key: "school-board-exams" },
        {
          value: "ಕಾಲೇಜು/ವಿಶ್ವವಿದ್ಯಾಲಯ ಪ್ರವೇಶ ಪರೀಕ್ಷೆಗಳು",
          key: "college-university-entrance-exams",
        },
        {
          value: "ಕಾಲೇಜು/ವಿಶ್ವವಿದ್ಯಾಲಯದ ಸೆಮಿಸ್ಟರ್ ಪರೀಕ್ಷೆಗಳು",
          key: "college-university-semester-exams",
        },
        { value: "ಸರ್ಕಾರಿ ಉದ್ಯೋಗ ಪರೀಕ್ಷೆಗಳು", key: "govt-job-exams" },
        { value: "CA, CS, CMA, CFA ಕೋರ್ಸ್‌ಗಳು", key: "professional-courses" },
        // { value: "Music", key: "music" },
        {
          value: "ವ್ಯಕ್ತಿತ್ವ ಅಭಿವೃದ್ಧಿ/ವ್ಯಾಪಾರ ತರಬೇತುದಾರ",
          key: "personality-development",
        },
        { value: "ಸ್ಟಾಕ್ ಮತ್ತು ಕ್ರಿಪ್ಟೋ ವ್ಯಾಪಾರ", key: "stock-trading" },
        // {value: "ಛಾಯಾಗ್ರಹಣ", key: "photography" },
        // { value: "ಭಾಷಾ ತರಬೇತಿ", key: "languages" },
        { value: "ಫಿಟ್ನೆಸ್", key: "fitness" },
        { value: "ಸಾಫ್ಟ್ವೇರ್ ತರಬೇತಿ", key: "software-training" },
        { value: "ಇತರರು", key: "others" },
      ],
    },
    isShown: false,
    next: "learnGrowthTips",
  },
  "youtuber-name": {
    type: "input",
    subtype: "text",
    id: "youtuber-name",
    key: "instituteName",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ YouTube ಚಾನಲ್ ಅಥವಾ Instagram ಹ್ಯಾಂಡಲ್‌ನ ಹೆಸರನ್ನು ನನಗೆ ತಿಳಿಸಿ",
      options: [],
    },
    next: "youtuber-subscribers",
    isShown: false,
    switchScreen: true,
  },
  "youtuber-number": {
    type: "input",
    subtype: "number",
    id: "youtuber-number",
    key: "phoneNumber",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸಂಪರ್ಕ ಸಂಖ್ಯೆಯನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
      options: [],
    },
    next: "youtuber-name",
    isSubmitted: false,
  },
  "youtuber-subscribers": {
    type: "radio",
    id: "youtuber-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "ನೀವು ಎಷ್ಟು ಚಂದಾದಾರರು/ಅನುಯಾಯಿಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ? ",
      options: [
        {
          value: "1,000 ಕ್ಕಿಂತ ಕಡಿಮೆ",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10,000 ಕ್ಕಿಂತ ಹೆಚ್ಚು",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "less-than-1000-intro": {
    type: "simple",
    id: "less-than-1000-intro",
    msg: "ಶ್ರೇಷ್ಠ! ಕ್ಲಾಸ್‌ಪ್ಲಸ್‌ನಲ್ಲಿ 1000 ಚಂದಾದಾರರು/ಅನುಯಾಯಿಗಳವರೆಗೆ ಕಂಟೆಂಟ್ ರಚನೆಕಾರರು ವರ್ಷಕ್ಕೆ ಸುಮಾರು 10 ಲಕ್ಷ ರೂ ಗಳಿಸುತ್ತಾರೆ",
    next: "youtuber-categories",
    isShown: false,
  },
  "1000-10000-intro": {
    type: "simple",
    id: "1000-10000-intro",
    msg: "ಅದ್ಭುತ! ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಬಳಸುತ್ತಿರುವ ಇತರರಂತೆ ಒಂದು ವರ್ಷದಲ್ಲಿ 30 ಲಕ್ಷ ರೂ.ಗಳವರೆಗೆ ಗಳಿಸುವ ಅವಕಾಶ ನಿಮಗಿದೆ",
    next: "youtuber-categories",
    isShown: false,
  },
  "10000-intro": {
    type: "simple",
    id: "10000-intro",
    msg: "ಅದ್ಭುತ! ಕ್ಲಾಸ್‌ಪ್ಲಸ್‌ನೊಂದಿಗೆ ಒಂದು ವರ್ಷದಲ್ಲಿ ನಿಮ್ಮ ಆದಾಯವನ್ನು 60 ಲಕ್ಷ ರೂ.ವರೆಗೆ ಹೆಚ್ಚಿಸಲು ಸಿದ್ಧರಾಗಿ",
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-email": {
    type: "input",
    subtype: "email",
    id: "youtuber-email",
    key: "email",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ.",
      options: [],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-number-intro": {
    type: "simple",
    id: "youtuber-number-intro",
    msg: "ನೀವು ಕಂಟೆಂಟ್‌ಪ್ರೆನಿಯರ್ ಆಗಲು ಕೆಲವೇ ಹಂತಗಳ ದೂರದಲ್ಲಿರುವಿರಿ!",
    next: "youtuber-number-intro-2",
    isShown: false,
  },
  "youtuber-number-intro-2": {
    type: "simple",
    id: "youtuber-number-intro-2",
    msg: "ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಅನ್ನು ಬಳಸುವ 50,000 ಕ್ಕೂ ಹೆಚ್ಚು YouTube/Instagram ವಿಷಯ ರಚನೆಕಾರರಿದ್ದಾರೆ ಮತ್ತು 3 ತಿಂಗಳಲ್ಲಿ ತಮ್ಮ ಆದಾಯವನ್ನು ಮೂರು ಪಟ್ಟು ಹೆಚ್ಚಿಸಿದ್ದಾರೆ.",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-name-exit": {
    type: "simple",
    id: "youtuber-name-exit",
    msg: "ನೀವು ಕಂಟೆಂಟ್‌ಪ್ರೆನಿಯರ್ ಆಗಲು ಕೆಲವೇ ಹಂತಗಳ ದೂರದಲ್ಲಿರುವಿರಿ!",
    next: "youtuber-number",
    isShown: false,
  },

  "teacher-consent": {
    type: "radio",
    id: "teacher-consent",

    question: {
      msg: "ನಿಮ್ಮ ಸ್ವಂತ ತರಬೇತಿ ವ್ಯವಹಾರವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಮತ್ತು ಶಿಕ್ಷಕರಾಗಲು ನೀವು ಬಯಸುತ್ತೀರಾ?",
      options: [
        {
          value: "ಹೌದು, ಖಂಡಿತ!",
          key: "yes",
          next: "teacher-name-intro",
        },
        {
          value: "ಹೇಗೆ ಪ್ರಾರಂಭಿಸಬೇಕೆಂದು ನನಗೆ ಖಚಿತವಿಲ್ಲ :(",
          key: "no",
          next: "teacher-name-negative-intro",
        },
      ],
    },
    next: "teacher-name",
    isShown: false,
  },
  "teacher-name-intro": {
    type: "simple",
    id: "teacher-name-intro",
    msg: "ಅದು ಅದ್ಭುತವಾಗಿದೆ! ಹೊಸ ಆನ್‌ಲೈನ್ ಪ್ರಯಾಣದೊಂದಿಗೆ ನಿಮಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡಲು ನಮಗೆ ಇನ್ನೂ ಕೆಲವು ವಿವರಗಳ ಅಗತ್ಯವಿದೆ!",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name-negative-intro": {
    type: "simple",
    id: "teacher-name-negative-intro",
    msg: "ಚಿಂತೆಯಿಲ್ಲ! ಆನ್‌ಲೈನ್ ಪ್ರಾರಂಭಿಸುವ ನಿಮ್ಮ ಮೊದಲ ಹಂತಗಳೊಂದಿಗೆ ನಾವು ನಿಮಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡುತ್ತೇವೆ",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "teacher-name",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಹೆಸರನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
      options: [],
    },
    next: "teacher-click-more",
    isShown: false,
    switchScreen: true,
  },

  "teacher-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "teacher-number",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸಂಪರ್ಕ ಸಂಖ್ಯೆಯನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
      options: [],
    },
    next: "teacher-name",
    isSubmitted: false,
  },
  "teacher-click-more": {
    type: "radio",
    id: "teacher-click-more",
    question: {
      msg: "ಕ್ಲಾಸ್‌ಪ್ಲಸ್‌ನೊಂದಿಗೆ ನೀವು ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಬೆಳೆಸಬಹುದು ಮತ್ತು ಸುಲಭವಾಗಿ ರಾಷ್ಟ್ರೀಯ ಬ್ರ್ಯಾಂಡ್ ಆಗಬಹುದು ಎಂದು ನಿಮಗೆ ತಿಳಿದಿದೆಯೇ.",
      options: [
        {
          value: "ನನಗೆ ಇನ್ನಷ್ಟು ಹೇಳು",
          key: "yes",
        },
      ],
    },
    next: "teacher-curious",
  },
  "teacher-curious": {
    type: "radio",
    id: "teacher-curious",
    question: {
      msg: "ಕ್ಲಾಸ್‌ಪ್ಲಸ್‌ನೊಂದಿಗೆ ನೀವು ಆನ್‌ಲೈನ್ ತರಗತಿಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು, ರೆಕಾರ್ಡ್ ಮಾಡಿದ ಉಪನ್ಯಾಸಗಳನ್ನು ಮಾರಾಟ ಮಾಡಬಹುದು, ಕಾರ್ಯಯೋಜನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಬಹುದು ಮತ್ತು ಹೆಚ್ಚಿನದನ್ನು ಮಾಡಬಹುದು!",
      options: [
        {
          value: "ಇದರ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳುವ ಕುತೂಹಲ ನನಗಿದೆ",
          key: "yes",
        },
      ],
    },
    next: "teacher-recorded-info",
  },
  "teacher-recorded-info": {
    type: "simple",
    id: "teacher-recorded-info",
    msg: "ರೆಕಾರ್ಡ್ ಮಾಡಿದ ಉಪನ್ಯಾಸಗಳನ್ನು ಹೊಂದಿರುವ ಶಿಕ್ಷಕರು ಆನ್‌ಲೈನ್ ಕೋರ್ಸ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವ ಮೂಲಕ ಹೆಚ್ಚುವರಿ ಆದಾಯವನ್ನು ಗಳಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ",
    next: "coaching-categories",
    isShown: false,
  },
  student: {
    type: "radio",
    id: "student",
    key: "student-consent",
    question: {
      msg: "ನಮಸ್ತೆ, ನಿಮ್ಮನ್ನು ಇಲ್ಲಿ ನೋಡಲು ನಮಗೆ ಸಂತೋಷವಾಗಿದೆ. ನಿಮ್ಮ ಕೋಚಿಂಗ್ ಇನ್‌ಸ್ಟಿಟ್ಯೂಟ್ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಕಲಿಸಲು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಬಳಸುತ್ತದೆಯೇ?",
      options: [
        {
          value: "ಇಲ್ಲ, ಅವರು ಮಾಡುವುದಿಲ್ಲ",
          key: "no",
          next: "share-classplus",
        },
        {
          value: "ಹೌದು ಅವರು ಮಾಡುತ್ತಾರೆ",
          key: "yes",
          next: "share-classplus-yes",
        },
      ],
    },
    next: "share-classplus",
  },

  "share-classplus": {
    type: "simple",
    id: "share-classplus",
    msg: "ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಅನ್ನು ಪ್ರಯತ್ನಿಸಲು ಮತ್ತು ರೂ 500 ಅಮೆಜಾನ್ ವೋಚರ್ ಗೆಲ್ಲುವ ಅವಕಾಶವನ್ನು ಪಡೆಯಲು ಅವರನ್ನು ಕೇಳಿ ",
    next: "student-teacher-number",
    isShown: false,
  },

  "student-teacher-number": {
    type: "input",
    subtype: "number",
    id: "student-teacher-number",
    key: "phoneNumber",
    question: {
      msg: "ನಿಮ್ಮ ಸಂಖ್ಯೆಯನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
      options: [],
    },
    next: "student-teacher-name",
    isSubmitted: false,
  },
  "student-teacher-name": {
    type: "input",
    subtype: "text",
    id: "student-teacher-name",
    key: "instituteName",
    question: {
      msg: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಹೆಸರನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
      options: [],
    },
    next: "learnGrowthTips",
    isSubmitted: false,
  },

  "share-classplus-yes": {
    type: "simple",
    id: "share-classplus-yes",
    msg: "ಕೇಳಲು ಚೆನ್ನಾಗಿದೆ! ಕ್ಲಾಸ್‌ಪ್ಲಸ್ ಅನ್ನು ಪ್ರಯತ್ನಿಸಲು ಮತ್ತು ರೂ 500 ಅಮೆಜಾನ್ ವೋಚರ್ ಗೆಲ್ಲುವ ಅವಕಾಶವನ್ನು ಪಡೆಯಲು ಅವರನ್ನು ಕೇಳಿ",
    next: "student-teacher-number",
    isShown: false,
  },

  learnGrowthTips: {
    type: "radio",
    id: "learnGrowthTips",
    key: "learnGrowthTips",
    question: {
      msg: "ನೀವು ಬೆಳವಣಿಗೆಯ ಸಲಹೆಗಳನ್ನು ಪಡೆಯಲು ಬಯಸುವಿರಾ?",
      options: [
        {
          value: "ಹೌದು",
          key: 1,
          next: "end",
        },
        {
          value: "ಇಲ್ಲ",
          key: 0,
          next: "end",
        },
      ],
    },
    next: "end",
    isShown: false,
    isSkippable: true,
  },
};

export const conversationsTA ={
  android: {
    type: "androidScreen",
    id: "android",
    msg: "",
    next: "student-count",
  },
  name: {
    type: "radio",
    id: "name",
    key: "isOnlineTutor",
    question: {
      msg: "நீங்கள் தயாரா? முதல் அடியை இங்கே எடுங்கள்",
      options: [
        {
          value: "நான் ஒரு பயிற்சி மையத்தை நடத்துகிறேன்",
          next: "coaching-number-intro",
          key: "coaching-center",
        },
        // {
        //   value: "நான் ஆன்லைன் படிப்பைத் தொடங்க விரும்புகிறேன்",
        //   next: "online-course-number",
        //   key: "online-course",
        // },

        {
          value: "நான் ஒரு உள்ளடக்கத்தை உருவாக்குபவர்",
          next: "youtuber-number-intro",
          key: "youtuber",
        },
        {
          value: "நான் ஒரு பள்ளியில் கற்பிக்கிறேன்",
          next: "teacher-consent",
          key: "school-teacher",
        },
        {
          value: "நான் ஒரு கல்வியாளர்/படைப்பாளர் அல்ல",
          next: "student-teacher-number",
          key: "student",
        },
      ],
    },
    next: "coaching-name",
    isShown: true,
  },
  "coaching-name-intro": {
    type: "simple",
    id: "coaching-name-intro",
    msg: "உங்கள் அறிவு மற்றும் ஞானத்தின் மூலம் உலகை மாற்றும் இந்த பொறுப்பை நீங்கள் ஏற்றுக்கொண்டது மிகவும் நல்லது.",
    next: "coaching-name",
    isShown: false,
  },
  "coaching-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "coaching-name",
    question: {
      msg: "உங்கள் பயிற்சி நிறுவனத்தின் பெயர் என்ன?",
      options: [],
    },
    next: "coaching-size-intro",
    isShown: false,
    switchScreen: true,
  },
  "coaching-number-intro": {
    type: "simple",
    id: "coaching-number-intro",
    msg: "நீங்கள் ஒரு ஆசிரியர் முன்னோடியாக மாற இன்னும் சில படிகள் உள்ளன!",
    next: "coaching-number-intro-2",
    isShown: false,
  },
  "coaching-number-intro-2": {
    type: "simple",
    id: "coaching-number-intro-2",
    msg: "உங்கள் ஆஃப்லைன் பயிற்சியை நீங்கள் நிர்வகிக்கலாம் மற்றும் உங்கள் ஆன்லைன் வணிகத்தை வளர்க்கலாம்.",
    next: "coaching-number",
    isShown: false,
  },

  // "coaching-number-intro-2": {
  //   type: "simple",
  //   id: "coaching-number-intro-2",
  //   msg: "We need a few more details to help you with the best tools you need",
  //   next: "coaching-number",
  //   isShown: false,
  // },
  "coaching-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "coaching-number",
    question: {
      msg: "உங்கள் தொடர்பு எண் என்ன?",
      options: [],
    },
    next: "coaching-name",
    isSubmitted: false,
  },
  "online-course-number-intro": {
    type: "simple",
    id: "online-course-number-intro",
    msg: "நீங்கள் ஒரு தனிமனிதனாக மாறுவதற்கு சில படிகள் மட்டுமே உள்ளன!",
    next: "online-course-number",
    isShown: false,
  },
  "online-course-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "online-course-number",
    question: {
      msg: "உங்கள் தொடர்பு எண் என்ன?",
      options: [],
    },
    next: "online-course-name",
    isSubmitted: false,
  },

  "online-course-name": {
    type: "input",
    subtype: "text",
    key: "instituteName",
    id: "online-course-name",
    question: {
      msg: "உங்களுடையது பெயர் என்ன?",
      options: [],
    },
    next: "online-course-is-creator",
    isSubmitted: false,
    switchScreen: true,
  },

  "online-course-is-creator": {
    type: "radio",
    id: "online-course-is-creator",
    key: "online-course-is-creator",
    question: {
      msg: "உங்களிடம் YouTube சேனல் அல்லது Instagram கைப்பிடி உள்ளதா?",
      options: [
        {
          value: "ஆம்",
          key: "1",
          next: "online-course-subscribers",
        },
        {
          value: "இல்லை",
          key: "0",
          next: "youtuber-categories",
        },
      ],
    },
    next: "online-course-subscribers",
    isShown: false,
  },

  "online-course-subscribers": {
    type: "radio",
    id: "online-course-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "உங்களுக்கு எத்தனை சந்தாதாரர்கள்/பின்பற்றுபவர்கள்  உள்ளனர்?",
      options: [
        {
          value: "1,000க்கும் குறைவானது",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 வரை 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10,000க்கு மேல்",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "coaching-size-intro": {
    type: "simple",
    id: "coaching-size-intro",
    msg: "உங்கள் பயிற்சி பற்றி எங்களுக்கு இன்னும் சில விவரங்கள் தேவை",
    next: "coaching-size",
    isShown: false,
  },

  "coaching-size": {
    type: "radio",
    id: "coaching-size",
    key: "numberOfStudents",
    question: {
      msg: "எத்தனை மாணவர்களுக்கு பாடம் நடத்துகிறீர்கள்? ",
      options: [
        {
          value: "0 - 50 மாணவர்கள்",
          key: "0-100",
          next: "0-100-intro",
        },
        {
          value: "50 - 250 மாணவர்கள்",
          key: "100-250",
          next: "100-250-intro",
        },
        { value: "250+ மாணவர்கள்", key: "250+", next: "250-intro" },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },

  "0-100-intro": {
    type: "simple",
    id: "0-100-intro",
    msg: "நன்று! 50 மாணவர்கள் வரை உள்ள கல்வியாளர்கள், ஆன்லைனில் படிப்புகளை விற்பனை செய்வதன் மூலமும், நேரலை வகுப்புகளை மேற்கொள்வதன் மூலமும், கிளாஸ்பிளஸில் ஆண்டுக்கு ரூ.10 லட்சம் சம்பாதிக்கிறார்கள்",
    next: "isRecordedContent",
    isShown: false,
  },
  "100-250-intro": {
    type: "simple",
    id: "100-250-intro",
    msg: "அருமை! ஆன்லைனில் பாடப்பிரிவுகளை விற்பனை செய்வதன் மூலமும், நேரலை வகுப்புகளை மேற்கொள்வதன் மூலமும் கிளாஸ்பிளஸைப் பயன்படுத்தும் மற்றவர்களைப் போலவே, நீங்கள் ஒரு வருடத்தில் ரூ. 30 லட்சம் வரை சம்பாதிக்க வாய்ப்பு உள்ளது",
    next: "isRecordedContent",
    isShown: false,
  },
  "250-intro": {
    type: "simple",
    id: "250-intro",
    msg: "ஆஹா! கிளாஸ்பிளஸ் மூலம் ஒரு வருடத்தில் உங்கள் வருமானத்தை ரூ. 60 லட்சமாக உயர்த்திக்கொள்ள தயாராகுங்கள்",
    next: "isRecordedContent",
    isShown: false,
  },
  "app-features": {
    type: "multiSelect",
    id: "app-features",
    key: "features",
    question: {
      msg: "பயன்பாட்டை எப்படிப் பயன்படுத்த விரும்புகிறீர்கள்?",
      options: [
        {
          value: "ஆன்லைன் படிப்புகளை விற்கவும்",
          key: "sell-courses",
        },
        {
          value: "நேரடி வகுப்புகள் எடுக்கவும்",
          key: "live-classes",
        },
        { value: "ஆன்லைன் சோதனை நடத்தவும்", key: "online-test" },
        { value: "தொகுதி நிர்வாகம்", key: "fee-attendance" },
      ],
    },
    next: "app-features-intro-2",
    isShown: false,
    isSkippable: true,
  },

  "coaching-categories": {
    type: "dropdown",
    id: "coaching-categories",
    key: "skillCategory",
    question: {
      msg: "நீங்கள் என்ன பாடங்களை கற்பிக்கிறீர்கள்?", // new content
      options: [
        { value: "பள்ளி வாரிய தேர்வுகள்", key: "school-board-exams" },
        {
          value: "கல்லூரி/பல்கலைக்கழக நுழைவுத் தேர்வுகள்",
          key: "college-university-entrance-exams",
        },
        {
          value: "கல்லூரி/பல்கலைக்கழக செமஸ்டர் தேர்வுகள்",
          key: "college-university-semester-exams",
        },
        { value: "அரசு வேலை தேர்வுகள்", key: "govt-job-exams" },
        { value: "CA, CS, CMA, CFA படிப்புகள்", key: "professional-courses" },
        { value: "கல்வி சாரா படிப்புகள்", key: "non-academic-courses" },
        { value: "மற்றவைகள்", key: "others" },
      ],
    },
    next: "learnGrowthTips",
    isShown: false,
  },

  isRecordedContent: {
    type: "radio",
    id: "isRecordedContent",
    key: "isRecordedContent",
    question: {
      msg: "உங்கள் ஆன்லைன் பயிற்சியைத் தொடங்க விரிவுரைகளை பதிவு செய்துள்ளீர்களா?",
      options: [
        {
          value: "ஆம் என்னிடம் இருக்கிறது",
          key: 1,
          next: "isRecordedYes",
        },
        {
          value: "இல்லை, நான் அதை உருவாக்குவேன்",
          key: 0,
          next: "isRecordedNo",
        },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },
  isRecordedYes: {
    type: "simple",
    id: "isRecordedYes",
    msg: "அருமை! உங்கள் பயன்பாட்டின் மூலம் இந்த விரிவுரைகளை விற்று அதிக பணம் சம்பாதிக்கலாம் என்பது உங்களுக்குத் தெரியுமா?",
    next: "coaching-categories",
    isShown: false,
  },
  isRecordedNo: {
    type: "simple",
    id: "isRecordedNo",
    msg: "கவலை வேண்டாம், எங்கள் பயன்பாட்டில் வீடியோக்களை உருவாக்குவது மிகவும் எளிதானது. உங்களுக்கு தேவையானது உள்ளடக்கம் மட்டுமே!",
    next: "coaching-categories",
    isShown: false,
  },
  "coaching-email": {
    type: "input",
    subtype: "text",
    id: "coaching-email",
    key: "email",
    question: {
      msg: "தயவுசெய்து உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்.",
      options: [],
    },
    next: "coaching-categories",
    isShown: false,
  },
  state: {
    type: "dropdown",
    id: "state",
    key: "state",
    question: {
      msg: "Classplus ஐப் பயன்படுத்தும் உங்கள் பகுதியில் உள்ள முன்னணி பயிற்சிகளை அறிய உங்கள் மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "state-youtubers": {
    type: "dropdown",
    id: "state-youtubers",
    key: "state",
    question: {
      msg: "Classplus ஐப் பயன்படுத்தும் உங்கள் பகுதியில் உள்ள உள்ளடக்க படைப்பாளர்களைத் தெரிந்துகொள்ள உங்கள் மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "youtuber-categories-intro": {
    type: "simple",
    id: "youtuber-categories-intro",
    msg: "அருமை! இந்தியாவின் முன்னணி உள்ளடக்க உருவாக்குனராக ஆவதற்கு நீங்கள் சரியான பாதையில் செல்கிறீர்கள்",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-categories": {
    type: "dropdown",
    id: "youtuber-categories",
    key: "skillCategory",
    question: {
      msg: "நீங்கள் எந்த வகையான உள்ளடக்கத்தை உருவாக்குகிறீர்கள்?", // new content
      options: [
        { value: "பள்ளி வாரிய தேர்வுகள்", key: "school-board-exams" },
        {
          value: "கல்லூரி/பல்கலைக்கழக நுழைவுத் தேர்வுகள்",
          key: "college-university-entrance-exams",
        },
        {
          value: "கல்லூரி/பல்கலைக்கழக செமஸ்டர் தேர்வுகள்",
          key: "college-university-semester-exams",
        },
        { value: "அரசு வேலை தேர்வுகள்", key: "govt-job-exams" },
        { value: "CA, CS, CMA, CFA படிப்புகள்", key: "professional-courses" },
        // { value: "Music", key: "music" },
        {
          value: "ஆளுமை வளர்ச்சி/வணிக பயிற்சியாளர்",
          key: "personality-development",
        },
        { value: "பங்கு மற்றும் கிரிப்டோ வர்த்தகம்", key: "stock-trading" },
        // {value: "Photography", key: "photography" },
        // { value: "Language Training", key: "languages" },
        { value: "உடற்தகுதி", key: "fitness" },
        { value: "மென்பொருள் பயிற்சி", key: "software-training" },
        { value: "மற்றவைகள்", key: "others" },
      ],
    },
    isShown: false,
    next: "learnGrowthTips",
  },
  "youtuber-name": {
    type: "input",
    subtype: "text",
    id: "youtuber-name",
    key: "instituteName",
    question: {
      msg: "உங்கள் YouTube சேனல் அல்லது இன்ஸ்டாகிராம் கைப்பிடியின் பெயரைச் சொல்லவும்",
      options: [],
    },
    next: "youtuber-subscribers",
    isShown: false,
    switchScreen: true,
  },
  "youtuber-number": {
    type: "input",
    subtype: "number",
    id: "youtuber-number",
    key: "phoneNumber",
    question: {
      msg: "உங்கள் தொடர்பு எண்ணைப் பகிரவும்",
      options: [],
    },
    next: "youtuber-name",
    isSubmitted: false,
  },
  "youtuber-subscribers": {
    type: "radio",
    id: "youtuber-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "உங்களுக்கு எத்தனை சந்தாதாரர்கள்/பின்தொடர்பவர்கள் உள்ளனர்?",
      options: [
        {
          value: "1,000க்கும் குறைவானது",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 இடையே 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10,000க்கு மேல்",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "less-than-1000-intro": {
    type: "simple",
    id: "less-than-1000-intro",
    msg: "நன்று! 1000 சந்தாதாரர்கள்/பின்தொடர்பவர்கள் வரை உள்ளடக்கத்தை உருவாக்குபவர்கள் Classplus இல் வருடத்திற்கு கிட்டத்தட்ட 10 லட்சம் சம்பாதிக்கிறார்கள்",
    next: "youtuber-categories",
    isShown: false,
  },
  "1000-10000-intro": {
    type: "simple",
    id: "1000-10000-intro",
    msg: "அருமை! கிளாஸ்பிளஸ் பயன்படுத்தும் மற்றவர்களைப் போலவே, ஒரு வருடத்தில் ரூ.30 லட்சம் வரை சம்பாதிக்க உங்களுக்கு வாய்ப்பு உள்ளது",
    next: "youtuber-categories",
    isShown: false,
  },
  "10000-intro": {
    type: "simple",
    id: "10000-intro",
    msg: "ஆஹா! கிளாஸ்பிளஸ் மூலம் ஒரு வருடத்தில் உங்கள் வருமானத்தை ரூ.60 லட்சமாக அதிகரிக்க தயாராகுங்கள்",
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-email": {
    type: "input",
    subtype: "email",
    id: "youtuber-email",
    key: "email",
    question: {
      msg: "தயவுசெய்து உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்.",
      options: [],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-number-intro": {
    type: "simple",
    id: "youtuber-number-intro",
    msg: "நீங்கள் ஒரு கன்டென்ட் ப்ரீனியராக மாற இன்னும் சில படிகள் மட்டுமே உள்ளன!",
    next: "youtuber-number-intro-2",
    isShown: false,
  },
  "youtuber-number-intro-2": {
    type: "simple",
    id: "youtuber-number-intro-2",
    msg: "50,000 க்கும் மேற்பட்ட YouTube/Instagram உள்ளடக்க உருவாக்குநர்கள் Classplus ஐப் பயன்படுத்தி 3 மாதங்களில் தங்கள் வருமானத்தை மூன்று மடங்காக உயர்த்தியுள்ளனர்.",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-name-exit": {
    type: "simple",
    id: "youtuber-name-exit",
    msg: "நீங்கள் ஒரு கன்டென்ட் ப்ரீனியராக மாற இன்னும் சில படிகள் மட்டுமே உள்ளன!",
    next: "youtuber-number",
    isShown: false,
  },

  "teacher-consent": {
    type: "radio",
    id: "teacher-consent",

    question: {
      msg: "நீங்கள் உங்கள் சொந்த பயிற்சித் தொழிலைத் தொடங்கி ஒரு ஆசிரியர் முன்னோடியாக விரும்புகிறீர்களா?",
      options: [
        {
          value: "ஆம், நிச்சயமாக!",
          key: "yes",
          next: "teacher-name-intro",
        },
        {
          value: "எப்படி ஆரம்பிப்பது என்று தெரியவில்லை:(",
          key: "no",
          next: "teacher-name-negative-intro",
        },
      ],
    },
    next: "teacher-name",
    isShown: false,
  },
  "teacher-name-intro": {
    type: "simple",
    id: "teacher-name-intro",
    msg: "அது அருமை! புதிய ஆன்லைன் பயணத்தில் உங்களுக்கு வழிகாட்ட எங்களுக்கு இன்னும் சில விவரங்கள் தேவை!",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name-negative-intro": {
    type: "simple",
    id: "teacher-name-negative-intro",
    msg: "கவலை இல்லை! ஆன்லைனில் தொடங்குவதற்கான உங்கள் முதல் படிகளை நாங்கள் உங்களுக்கு வழிகாட்டுவோம்",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "teacher-name",
    question: {
      msg: "உங்கள் பெயரைப் பகிரவும்",
      options: [],
    },
    next: "teacher-click-more",
    isShown: false,
    switchScreen: true,
  },

  "teacher-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "teacher-number",
    question: {
      msg: "உங்கள் தொடர்பு எண்ணைப் பகிரவும்",
      options: [],
    },
    next: "teacher-name",
    isSubmitted: false,
  },
  "teacher-click-more": {
    type: "radio",
    id: "teacher-click-more",
    question: {
      msg: "Classplus மூலம் உங்கள் வணிகத்தை ஆன்லைனில் எளிதாக வளர்த்து தேசிய பிராண்டாக மாறலாம் என்பது உங்களுக்குத் தெரியுமா?",
      options: [
        {
          value: "இன்னும் எனக்கு சொல்லுங்கள்",
          key: "yes",
        },
      ],
    },
    next: "teacher-curious",
  },
  "teacher-curious": {
    type: "radio",
    id: "teacher-curious",
    question: {
      msg: "Classplus மூலம் நீங்கள் ஆன்லைன் வகுப்புகளை எடுக்கலாம், பதிவு செய்யப்பட்ட விரிவுரைகளை விற்கலாம், பணிகளைப் பகிரலாம் மற்றும் பலவற்றை செய்யலாம்!",
      options: [
        {
          value: "இதைப் பற்றி மேலும் அறிய ஆவலாக உள்ளேன்",
          key: "yes",
        },
      ],
    },
    next: "teacher-recorded-info",
  },
  "teacher-recorded-info": {
    type: "simple",
    id: "teacher-recorded-info",
    msg: "பதிவுசெய்யப்பட்ட விரிவுரைகளைக் கொண்ட ஆசிரியர்கள் ஆன்லைன் படிப்புகளை விற்பனை செய்வதன் மூலம் கூடுதல் வருமானம் ஈட்ட முடியும்",
    next: "coaching-categories",
    isShown: false,
  },
  student: {
    type: "radio",
    id: "student",
    key: "student-consent",
    question: {
      msg: "ஐயா, உங்களை இங்கு காண்பதில் நாங்கள் மகிழ்ச்சியடைகிறோம். ஆன்லைனில் கற்பிக்க உங்கள் பயிற்சி நிறுவனம் மொபைல் பயன்பாட்டைப் பயன்படுத்துகிறதா?",
      options: [
        {
          value: "இல்லை, அவர்கள் இல்லை",
          key: "no",
          next: "share-classplus",
        },
        {
          value: "ஆம் அவர்கள் செய்கிறார்கள்",
          key: "yes",
          next: "share-classplus-yes",
        },
      ],
    },
    next: "share-classplus",
  },

  "share-classplus": {
    type: "simple",
    id: "share-classplus",
    msg: "Classplusஐ முயற்சித்து, 500 ரூபாய் அமேசான் வவுச்சரை வெல்லும் வாய்ப்பைப் பெறச் சொல்லுங்கள்",
    next: "student-teacher-number",
    isShown: false,
  },

  "student-teacher-number": {
    type: "input",
    subtype: "number",
    id: "student-teacher-number",
    key: "phoneNumber",
    question: {
      msg: "உங்கள் எண்ணை இங்கே உள்ளிடவும்",
      options: [],
    },
    next: "student-teacher-name",
    isSubmitted: false,
  },
  "student-teacher-name": {
    type: "input",
    subtype: "text",
    id: "student-teacher-name",
    key: "instituteName",
    question: {
      msg: "உங்கள் பெயரைப் பகிரவும்",
      options: [],
    },
    next: "learnGrowthTips",
    isSubmitted: false,
  },

  "share-classplus-yes": {
    type: "simple",
    id: "share-classplus-yes",
    msg: "கேட்கவே நன்றாக இருக்கிறது! Classplusஐ முயற்சித்து, 500 ரூபாய் அமேசான் வவுச்சரை வெல்லும் வாய்ப்பைப் பெறச் சொல்லுங்கள்",
    next: "student-teacher-number",
    isShown: false,
  },

  learnGrowthTips: {
    type: "radio",
    id: "learnGrowthTips",
    key: "learnGrowthTips",
    question: {
      msg: "வளர்ச்சி உதவிக்குறிப்புகளைப் பெற விரும்புகிறீர்களா?",
      options: [
        {
          value: "ஆம்",
          key: 1,
          next: "end",
        },
        {
          value: "இல்லை",
          key: 0,
          next: "end",
        },
      ],
    },
    next: "end",
    isShown: false,
    isSkippable: true,
  },
};

export const conversationsMA ={
  android: {
    type: "androidScreen",
    id: "android",
    msg: "",
    next: "student-count",
  },
  name: {
    type: "radio",
    id: "name",
    key: "isOnlineTutor",
    question: {
      msg: "നിങ്ങൾ തയാറാണോ? ഇവിടെ ആദ്യപടി സ്വീകരിക്കുക",
      options: [
        {
          value: "ഞാൻ ഒരു കോച്ചിംഗ് സെന്റർ നടത്തുന്നു",
          next: "coaching-number-intro",
          key: "coaching-center",
        },
        // {
        //   value: "ഒരു ഓൺലൈൻ കോഴ്‌സ് ആരംഭിക്കാൻ ഞാൻ ആഗ്രഹിക്കുന്നു",
        //   next: "online-course-number",
        //   key: "online-course",
        // },

        {
          value: "ഞാൻ ഒരു ഉള്ളടക്ക സ്രഷ്ടാവാണ്",
          next: "youtuber-number-intro",
          key: "youtuber",
        },
        {
          value: "ഞാൻ ഒരു സ്കൂളിൽ പഠിപ്പിക്കുന്നു",
          next: "teacher-consent",
          key: "school-teacher",
        },
        {
          value: "ഞാൻ ഒരു അദ്ധ്യാപകൻ/സ്രഷ്ടാവ് അല്ല",
          next: "student-teacher-number",
          key: "student",
        },
      ],
    },
    next: "coaching-name",
    isShown: true,
  },
  "coaching-name-intro": {
    type: "simple",
    id: "coaching-name-intro",
    msg: "നിങ്ങളുടെ അറിവിലൂടെയും ജ്ഞാനത്തിലൂടെയും ലോകത്തെ മാറ്റാനുള്ള ഈ ഉത്തരവാദിത്തം നിങ്ങൾ ഏറ്റെടുത്തതിൽ സന്തോഷമുണ്ട്.",
    next: "coaching-name",
    isShown: false,
  },
  "coaching-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "coaching-name",
    question: {
      msg: "നിങ്ങളുടെ കോച്ചിംഗ് ഇൻസ്റ്റിറ്റ്യൂട്ടിന്റെ പേരെന്താണ്?",
      options: [],
    },
    next: "coaching-size-intro",
    isShown: false,
    switchScreen: true,
  },
  "coaching-number-intro": {
    type: "simple",
    id: "coaching-number-intro",
    msg: "ഒരു അദ്ധ്യാപകനാകാൻ നിങ്ങൾ ഏതാനും ചുവടുകൾ മാത്രം അകലെയാണ്!",
    next: "coaching-number-intro-2",
    isShown: false,
  },
  "coaching-number-intro-2": {
    type: "simple",
    id: "coaching-number-intro-2",
    msg: "നിങ്ങൾക്ക് ഓഫ്‌ലൈൻ കോച്ചിംഗ് മാനേജ് ചെയ്യാനും നിങ്ങളുടെ ഓൺലൈൻ ബിസിനസ്സ് വളർത്താനും കഴിയും.",
    next: "coaching-number",
    isShown: false,
  },

  // "coaching-number-intro-2": {
  //   type: "simple",
  //   id: "coaching-number-intro-2",
  //   msg: "We need a few more details to help you with the best tools you need",
  //   next: "coaching-number",
  //   isShown: false,
  // },
  "coaching-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "coaching-number",
    question: {
      msg: "നിങ്ങളുടെ കോൺടാക്റ്റ് നമ്പർ എന്താണ്?",
      options: [],
    },
    next: "coaching-name",
    isSubmitted: false,
  },
  "online-course-number-intro": {
    type: "simple",
    id: "online-course-number-intro",
    msg: "നിങ്ങൾ ഒരു സോളോപ്രണർ ആകുന്നതിന് ഏതാനും ചുവടുകൾ മാത്രം അകലെയാണ്!",
    next: "online-course-number",
    isShown: false,
  },
  "online-course-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "online-course-number",
    question: {
      msg: "നിങ്ങളുടെ കോൺടാക്റ്റ് നമ്പർ എന്താണ്?",
      options: [],
    },
    next: "online-course-name",
    isSubmitted: false,
  },

  "online-course-name": {
    type: "input",
    subtype: "text",
    key: "instituteName",
    id: "online-course-name",
    question: {
      msg: "What's your name?",
      options: [],
    },
    next: "online-course-is-creator",
    isSubmitted: false,
    switchScreen: true,
  },

  "online-course-is-creator": {
    type: "radio",
    id: "online-course-is-creator",
    key: "online-course-is-creator",
    question: {
      msg: "നിങ്ങൾക്ക് ഒരു YouTube ചാനലോ ഇൻസ്റ്റാഗ്രാം ഹാൻഡിലോ ഉണ്ടോ?",
      options: [
        {
          value: "അതെ",
          key: "1",
          next: "online-course-subscribers",
        },
        {
          value: "ഇല്ല",
          key: "0",
          next: "youtuber-categories",
        },
      ],
    },
    next: "online-course-subscribers",
    isShown: false,
  },

  "online-course-subscribers": {
    type: "radio",
    id: "online-course-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "നിങ്ങൾക്ക് എത്ര സബ്‌സ്‌ക്രൈബർമാർ/അനുയായികളുണ്ട്?",
      options: [
        {
          value: "1,000-ൽ താഴെ",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10,000-ത്തിലധികം",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "coaching-size-intro": {
    type: "simple",
    id: "coaching-size-intro",
    msg: "നിങ്ങളുടെ പരിശീലനത്തെക്കുറിച്ച് ഞങ്ങൾക്ക് കുറച്ച് വിശദാംശങ്ങൾ കൂടി ആവശ്യമാണ്",
    next: "coaching-size",
    isShown: false,
  },

  "coaching-size": {
    type: "radio",
    id: "coaching-size",
    key: "numberOfStudents",
    question: {
      msg: "നിങ്ങൾ എത്ര വിദ്യാർത്ഥികളെ പഠിപ്പിക്കുന്നു?",
      options: [
        {
          value: "0 - 50 വിദ്യാർത്ഥികൾ",
          key: "0-100",
          next: "0-100-intro",
        },
        {
          value: "100-250 വിദ്യാർത്ഥികൾ",
          key: "100-250",
          next: "100-250-intro",
        },
        { value: "250+ വിദ്യാർത്ഥികൾ", കീ: "250+", അടുത്തത്: "250-ആമുഖം" },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },

  "0-100-intro": {
    type: "simple",
    id: "0-100-intro",
    msg: "കൊള്ളാം! കോഴ്‌സുകൾ ഓൺലൈനായി വിറ്റ് തത്സമയ ക്ലാസുകൾ നടത്തി 50 വിദ്യാർത്ഥികളുള്ള അധ്യാപകർ ക്ലാസ് പ്ലസ്സിൽ പ്രതിവർഷം 10 ലക്ഷം രൂപ സമ്പാദിക്കുന്നു.",
    next: "isRecordedContent",
    isShown: false,
  },
  "100-250-intro": {
    type: "simple",
    id: "100-250-intro",
    msg: "ഗംഭീരം! കോഴ്‌സുകൾ ഓൺലൈനായി വിറ്റ് തത്സമയ ക്ലാസുകൾ എടുത്ത് ക്ലാസ് പ്ലസ് ഉപയോഗിക്കുന്ന മറ്റുള്ളവരെ പോലെ നിങ്ങൾക്ക് ഒരു വർഷം 30 ലക്ഷം രൂപ വരെ സമ്പാദിക്കാനുള്ള അവസരമുണ്ട്.",
    next: "isRecordedContent",
    isShown: false,
  },
  "250-intro": {
    type: "simple",
    id: "250-intro",
    msg: "വൗ! കോഴ്‌സുകൾ ഓൺലൈനായി വിറ്റ് തത്സമയ ക്ലാസുകൾ നടത്തി ക്ലാസ് പ്ലസ് ഉപയോഗിച്ച് ഒരു വർഷം നിങ്ങളുടെ വരുമാനം 60 ലക്ഷം രൂപ വരെ വർദ്ധിപ്പിക്കാൻ തയ്യാറാകൂ",
    next: "isRecordedContent",
    isShown: false,
  },
  "app-features": {
    type: "multiSelect",
    id: "app-features",
    key: "features",
    question: {
      msg: "ആപ്പ് എങ്ങനെ ഉപയോഗിക്കാനാണ് നിങ്ങൾ ആഗ്രഹിക്കുന്നത്?",
      options: [
        {
          value: "ഓൺലൈൻ കോഴ്സുകൾ വിൽക്കുക",
          key: "sell-courses",
        },
        {
          value: "തത്സമയ ക്ലാസുകൾ എടുക്കുക",
          key: "live-classes",
        },
        { value: "ഓൺലൈൻ ടെസ്റ്റ് നടത്തുക", കീ: "ഓൺലൈൻ-ടെസ്റ്റ്" },
        { value: "ബാച്ച് മാനേജ്മെന്റ്", കീ: "ഫീ-ഹാജർ" },
      ],
    },
    next: "app-features-intro-2",
    isShown: false,
    isSkippable: true,
  },

  "coaching-categories": {
    type: "dropdown",
    id: "coaching-categories",
    key: "skillCategory",
    question: {
      msg: "ഏതൊക്കെ വിഷയങ്ങളാണ് നിങ്ങൾ പഠിപ്പിക്കുന്നത്?", // new content
      options: [
        { value: "സ്കൂൾ ബോർഡ് പരീക്ഷകൾ", കീ: "സ്കൂൾ-ബോർഡ് പരീക്ഷകൾ" },
        {
          value: "കോളേജ്/യൂണിവേഴ്സിറ്റി പ്രവേശന പരീക്ഷകൾ",
          key: "college-university-entrance-exams",
        },
        {
          value: "കോളേജ്/യൂണിവേഴ്സിറ്റി സെമസ്റ്റർ പരീക്ഷകൾ",
          key: "college-university-semester-exams",
        },
        { value: "സർക്കാർ ജോലി പരീക്ഷകൾ", കീ: "സർക്കാർ-ജോബ് പരീക്ഷകൾ" },
        { value: "CA, CS, CMA, CFA കോഴ്‌സുകൾ", കീ: "പ്രൊഫഷണൽ-കോഴ്‌സുകൾ" },
        { value: "നോൺ അക്കാദമിക് കോഴ്‌സുകൾ", കീ: "അക്കാദമിക്-കോഴ്‌സുകൾ" },
        { value: "മറ്റുള്ളവ", കീ: "മറ്റുള്ളവ" },
      ],
    },
    next: "learnGrowthTips",
    isShown: false,
  },

  isRecordedContent: {
    type: "radio",
    id: "isRecordedContent",
    key: "isRecordedContent",
    question: {
      msg: "നിങ്ങളുടെ ഓൺലൈൻ കോച്ചിംഗ് ആരംഭിക്കുന്നതിനുള്ള പ്രഭാഷണങ്ങൾ റെക്കോർഡ് ചെയ്‌തിട്ടുണ്ടോ?",
      options: [
        {
          value: "അതെ, എനിക്കുണ്ട്",
          key: 1,
          next: "isRecordedYes",
        },
        {
          value: "ഇല്ല, ഞാനത് സൃഷ്ടിക്കും",
          key: 0,
          next: "isRecordedNo",
        },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },
  isRecordedYes: {
    type: "simple",
    id: "isRecordedYes",
    msg: "ഗംഭീരം! നിങ്ങളുടെ ആപ്പ് വഴി നിങ്ങൾക്ക് ഈ പ്രഭാഷണങ്ങൾ വിൽക്കാനും കൂടുതൽ പണം സമ്പാദിക്കാനും കഴിയുമെന്ന് നിങ്ങൾക്കറിയാമോ",
    next: "coaching-categories",
    isShown: false,
  },
  isRecordedNo: {
    type: "simple",
    id: "isRecordedNo",
    msg: "വിഷമിക്കേണ്ട, ഞങ്ങളുടെ ആപ്പിൽ വീഡിയോകൾ സൃഷ്‌ടിക്കുന്നത് വളരെ എളുപ്പമാണ്. നിങ്ങൾക്ക് വേണ്ടത് ഉള്ളടക്കം മാത്രമാണ്!",
    next: "coaching-categories",
    isShown: false,
  },
  "coaching-email": {
    type: "input",
    subtype: "text",
    id: "coaching-email",
    key: "email",
    question: {
      msg: "ദയവായി നിങ്ങളുടെ ഇമെയിൽ വിലാസം നൽകുക.",
      options: [],
    },
    next: "coaching-categories",
    isShown: false,
  },
  state: {
    type: "dropdown",
    id: "state",
    key: "state",
    question: {
      msg: "Classplus ഉപയോഗിക്കുന്ന നിങ്ങളുടെ പ്രദേശത്തെ മുൻനിര കോച്ചിംഗുകളെ അറിയാൻ നിങ്ങളുടെ സംസ്ഥാനം തിരഞ്ഞെടുക്കുക",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "state-youtubers": {
    type: "dropdown",
    id: "state-youtubers",
    key: "state",
    question: {
      msg: "Classplus ഉപയോഗിക്കുന്ന നിങ്ങളുടെ പ്രദേശത്തെ ഉള്ളടക്ക സ്രഷ്‌ടാക്കളെ അറിയാൻ നിങ്ങളുടെ സംസ്ഥാനം തിരഞ്ഞെടുക്കുക",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "youtuber-categories-intro": {
    type: "simple",
    id: "youtuber-categories-intro",
    msg: "ഗംഭീരം! ഇന്ത്യയിലെ മുൻനിര ഉള്ളടക്ക സ്രഷ്ടാവാകാനുള്ള ശരിയായ പാതയിലാണ് നിങ്ങൾ",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-categories": {
    type: "dropdown",
    id: "youtuber-categories",
    key: "skillCategory",
    question: {
      msg: "ഏത് തരത്തിലുള്ള ഉള്ളടക്കമാണ് നിങ്ങൾ സൃഷ്ടിക്കുന്നത്?", // new content
      options: [
        { value: "സ്കൂൾ ബോർഡ് പരീക്ഷകൾ", കീ: "സ്കൂൾ-ബോർഡ് പരീക്ഷകൾ" },
        {
          value: "കോളേജ്/യൂണിവേഴ്സിറ്റി പ്രവേശന പരീക്ഷകൾ",
          key: "college-university-entrance-exams",
        },
        {
          value: "കോളേജ്/യൂണിവേഴ്സിറ്റി സെമസ്റ്റർ പരീക്ഷകൾ",
          key: "college-university-semester-exams",
        },
        { value: "സർക്കാർ ജോലി പരീക്ഷകൾ", കീ: "സർക്കാർ-ജോബ് പരീക്ഷകൾ" },
        { value: "CA, CS, CMA, CFA കോഴ്‌സുകൾ", കീ: "പ്രൊഫഷണൽ-കോഴ്‌സുകൾ" },
        // { value: "Music", key: "music" },
        {
          value: "വ്യക്തിത്വ വികസനം/ബിസിനസ് കോച്ച്",
          key: "personality-development",
        },
        { value: "സ്റ്റോക്ക് ആൻഡ് ക്രിപ്റ്റോ ട്രേഡിംഗ്", കീ: "സ്റ്റോക്ക് ട്രേഡിംഗ്" },
        // {value: "Photography", key: "photography" },
        // { value: "Language Training", key: "languages" },
        { value: "ഫിറ്റ്നസ്", കീ: "ഫിറ്റ്നസ്" },
        { value: "സോഫ്റ്റ്‌വെയർ പരിശീലനം", കീ: "സോഫ്റ്റ്‌വെയർ-പരിശീലനം" },
        { value: "മറ്റുള്ളവ", കീ: "മറ്റുള്ളവ" },
      ],
    },
    isShown: false,
    next: "learnGrowthTips",
  },
  "youtuber-name": {
    type: "input",
    subtype: "text",
    id: "youtuber-name",
    key: "instituteName",
    question: {
      msg: "നിങ്ങളുടെ YouTube ചാനലിന്റെയോ ഇൻസ്റ്റാഗ്രാം ഹാൻഡിന്റെയോ പേര് എന്നോട് പറയൂ",
      options: [],
    },
    next: "youtuber-subscribers",
    isShown: false,
    switchScreen: true,
  },
  "youtuber-number": {
    type: "input",
    subtype: "number",
    id: "youtuber-number",
    key: "phoneNumber",
    question: {
      msg: "നിങ്ങളുടെ കോൺടാക്റ്റ് നമ്പർ പങ്കിടുക",
      options: [],
    },
    next: "youtuber-name",
    isSubmitted: false,
  },
  "youtuber-subscribers": {
    type: "radio",
    id: "youtuber-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "നിങ്ങൾക്ക് എത്ര സബ്‌സ്‌ക്രൈബർമാർ/അനുയായികളുണ്ട്? ",
      options: [
        {
          value: "1,000-ൽ താഴെ",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10,000-ത്തിലധികം",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "less-than-1000-intro": {
    type: "simple",
    id: "less-than-1000-intro",
    msg: "കൊള്ളാം! 1000 വരെ സബ്‌സ്‌ക്രൈബർമാർ/അനുയായികൾ ഉള്ള ഉള്ളടക്ക സ്രഷ്‌ടാക്കൾ ക്ലാസ്പ്ലസിൽ പ്രതിവർഷം 10 ലക്ഷം രൂപ സമ്പാദിക്കുന്നു",
    next: "youtuber-categories",
    isShown: false,
  },
  "1000-10000-intro": {
    type: "simple",
    id: "1000-10000-intro",
    msg: "ഗംഭീരം! ക്ലാസ് പ്ലസ് ഉപയോഗിക്കുന്ന മറ്റുള്ളവരെ പോലെ ഒരു വർഷം 30 ലക്ഷം രൂപ വരെ സമ്പാദിക്കാൻ നിങ്ങൾക്ക് അവസരമുണ്ട്",
    next: "youtuber-categories",
    isShown: false,
  },
  "10000-intro": {
    type: "simple",
    id: "10000-intro",
    msg: "വൗ! ക്ലാസ് പ്ലസ് ഉപയോഗിച്ച് ഒരു വർഷം കൊണ്ട് നിങ്ങളുടെ വരുമാനം 60 ലക്ഷം രൂപയായി വർധിപ്പിക്കാൻ തയ്യാറാകൂ",
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-email": {
    type: "input",
    subtype: "email",
    id: "youtuber-email",
    key: "email",
    question: {
      msg: "ദയവായി നിങ്ങളുടെ ഇമെയിൽ വിലാസം നൽകുക.",
      options: [],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-number-intro": {
    type: "simple",
    id: "youtuber-number-intro",
    msg: "ഒരു ഉള്ളടക്കപ്രിയർ ആകുന്നതിന് നിങ്ങൾ ഏതാനും ചുവടുകൾ മാത്രം അകലെയാണ്!",
    next: "youtuber-number-intro-2",
    isShown: false,
  },
  "youtuber-number-intro-2": {
    type: "simple",
    id: "youtuber-number-intro-2",
    msg: "Classplus ഉപയോഗിക്കുന്ന 50,000-ത്തിലധികം YouTube/Instagram ഉള്ളടക്ക സ്രഷ്‌ടാക്കളുണ്ട്, അവർ 3 മാസത്തിനുള്ളിൽ അവരുടെ വരുമാനം മൂന്നിരട്ടിയാക്കി.",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-name-exit": {
    type: "simple",
    id: "youtuber-name-exit",
    msg: "ഒരു ഉള്ളടക്കപ്രിയർ ആകുന്നതിന് നിങ്ങൾ ഏതാനും ചുവടുകൾ മാത്രം അകലെയാണ്!",
    next: "youtuber-number",
    isShown: false,
  },

  "teacher-consent": {
    type: "radio",
    id: "teacher-consent",

    question: {
      msg: "നിങ്ങളുടെ സ്വന്തം കോച്ചിംഗ് ബിസിനസ്സ് ആരംഭിച്ച് ഒരു ടീച്ചർപ്രണർ ആകാൻ നിങ്ങൾ നോക്കുകയാണോ?",
      options: [
        {
          value: "അതെ തീർച്ചയായും!",
          key: "yes",
          next: "teacher-name-intro",
        },
        {
          value: "എങ്ങനെ തുടങ്ങണമെന്ന് എനിക്കറിയില്ല :(",
          key: "no",
          next: "teacher-name-negative-intro",
        },
      ],
    },
    next: "teacher-name",
    isShown: false,
  },
  "teacher-name-intro": {
    type: "simple",
    id: "teacher-name-intro",
    msg: "അത് കൊള്ളാം! പുതിയ ഓൺലൈൻ യാത്രയിൽ നിങ്ങളെ നയിക്കാൻ ഞങ്ങൾക്ക് കുറച്ച് വിശദാംശങ്ങൾ കൂടി ആവശ്യമാണ്!",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name-negative-intro": {
    type: "simple",
    id: "teacher-name-negative-intro",
    msg: "വിഷമിക്കേണ്ടതില്ല! ഓൺലൈനിൽ ആരംഭിക്കുന്നതിനുള്ള നിങ്ങളുടെ ആദ്യ ഘട്ടങ്ങൾ ഞങ്ങൾ നിങ്ങളെ നയിക്കും",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "teacher-name",
    question: {
      msg: "ദയവായി നിങ്ങളുടെ പേര് പങ്കിടുക",
      options: [],
    },
    next: "teacher-click-more",
    isShown: false,
    switchScreen: true,
  },

  "teacher-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "teacher-number",
    question: {
      msg: "നിങ്ങളുടെ കോൺടാക്റ്റ് നമ്പർ പങ്കിടുക",
      options: [],
    },
    next: "teacher-name",
    isSubmitted: false,
  },
  "teacher-click-more": {
    type: "radio",
    id: "teacher-click-more",
    question: {
      msg: "Classplus ഉപയോഗിച്ച് നിങ്ങളുടെ ബിസിനസ്സ് ഓൺലൈനിൽ വളർത്താനും എളുപ്പത്തിൽ ഒരു ദേശീയ ബ്രാൻഡാകാനും കഴിയുമെന്ന് നിങ്ങൾക്കറിയാമോ.",
      options: [
        {
          value: "എന്നോട് കൂടുതൽ പറയൂ",
          key: "yes",
        },
      ],
    },
    next: "teacher-curious",
  },
  "teacher-curious": {
    type: "radio",
    id: "teacher-curious",
    question: {
      msg: "Classplus ഉപയോഗിച്ച് നിങ്ങൾക്ക് ഓൺലൈൻ ക്ലാസുകൾ എടുക്കാനും റെക്കോർഡ് ചെയ്ത പ്രഭാഷണങ്ങൾ വിൽക്കാനും അസൈൻമെന്റുകൾ പങ്കിടാനും മറ്റും കഴിയും!",
      options: [
        {
          value: "ഇതിനെക്കുറിച്ച് കൂടുതലറിയാൻ എനിക്ക് ആകാംക്ഷയുണ്ട്",
          key: "yes",
        },
      ],
    },
    next: "teacher-recorded-info",
  },
  "teacher-recorded-info": {
    type: "simple",
    id: "teacher-recorded-info",
    msg: "റെക്കോർഡ് ചെയ്ത പ്രഭാഷണങ്ങളുള്ള അധ്യാപകർക്ക് ഓൺലൈൻ കോഴ്സുകൾ വിൽക്കുന്നതിലൂടെ അധിക വരുമാനം നേടാൻ കഴിയും",
    next: "coaching-categories",
    isShown: false,
  },
  student: {
    type: "radio",
    id: "student",
    key: "student-consent",
    question: {
      msg: "ഹായ്, നിങ്ങളെ ഇവിടെ കണ്ടതിൽ ഞങ്ങൾക്ക് സന്തോഷമുണ്ട്. ഓൺലൈനിൽ പഠിപ്പിക്കാൻ നിങ്ങളുടെ കോച്ചിംഗ് ഇൻസ്റ്റിറ്റ്യൂട്ട് ഒരു മൊബൈൽ ആപ്പ് ഉപയോഗിക്കുന്നുണ്ടോ?",
      options: [
        {
          value: "ഇല്ല, അവർ ചെയ്യുന്നില്ല",
          key: "no",
          next: "share-classplus",
        },
        {
          value: "അതേ അവർ ചെയ്യും",
          key: "yes",
          next: "share-classplus-yes",
        },
      ],
    },
    next: "share-classplus",
  },

  "share-classplus": {
    type: "simple",
    id: "share-classplus",
    msg: "Classplus പരീക്ഷിച്ച് 500 രൂപ ആമസോൺ വൗച്ചർ നേടാനുള്ള അവസരം അവരോട് ആവശ്യപ്പെടുക",
    next: "student-teacher-number",
    isShown: false,
  },

  "student-teacher-number": {
    type: "input",
    subtype: "number",
    id: "student-teacher-number",
    key: "phoneNumber",
    question: {
      msg: "നിങ്ങളുടെ നമ്പർ ഇവിടെ നൽകുക",
      options: [],
    },
    next: "student-teacher-name",
    isSubmitted: false,
  },
  "student-teacher-name": {
    type: "input",
    subtype: "text",
    id: "student-teacher-name",
    key: "instituteName",
    question: {
      msg: "ദയവായി നിങ്ങളുടെ പേര് പങ്കിടുക",
      options: [],
    },
    next: "learnGrowthTips",
    isSubmitted: false,
  },

  "share-classplus-yes": {
    type: "simple",
    id: "share-classplus-yes",
    msg: "കേൾക്കാൻ നല്ല രസമുണ്ട്! Classplus പരീക്ഷിച്ച് 500 രൂപ ആമസോൺ വൗച്ചർ നേടാനുള്ള അവസരം അവരോട് ആവശ്യപ്പെടുക",
    next: "student-teacher-number",
    isShown: false,
  },

  learnGrowthTips: {
    type: "radio",
    id: "learnGrowthTips",
    key: "learnGrowthTips",
    question: {
      msg: "നിങ്ങൾക്ക് വളർച്ചാ നുറുങ്ങുകൾ ലഭിക്കണോ?",
      options: [
        {
          value: "അതെ",
          key: 1,
          next: "end",
        },
        {
          value: "ഇല്ല",
          key: 0,
          next: "end",
        },
      ],
    },
    next: "end",
    isShown: false,
    isSkippable: true,
  },
}


export const conversationsTEL = {
  android: {
    type: "androidScreen",
    id: "android",
    msg: "",
    next: "student-count",
  },
  name: {
    type: "radio",
    id: "name",
    key: "isOnlineTutor",
    question: {
      msg: "మీరు సిద్ధంగా ఉన్నారా? అయితే మొదటి అడుగు వేయండి",
      options: [
        {
          value: "నేను ఒక కోచింగ్ సెంటర్ నిర్వహిస్తున్నాను",
          next: "coaching-number-intro",
          key: "coaching-center",
        },
        // {
        //   value: "నేను ఒక ఆన్లైన్ కోర్స్ ప్రారంభించాలనుకుంటున్నాను",
        //   next: "online-course-number",
        //   key: "online-course",
        // },

        {
          value: "నేను ఒక కంటెంట్ రూపకర్తని",
          next: "youtuber-number-intro",
          key: "youtuber",
        },
        {
          value: "నేను స్కూల్లో బోధిస్తాను",
          next: "teacher-consent",
          key: "school-teacher",
        },
        {
          value: "నేను విద్యావేత్తని/క్రియేటర్నీ కాదు",
          next: "student-teacher-number",
          key: "student",
        },
      ],
    },
    next: "coaching-name",
    isShown: true,
  },
  "coaching-name-intro": {
    type: "simple",
    id: "coaching-name-intro",
    msg: "మీరు మీ యొక్క పరిజ్ఞానంతో ప్రపంచాన్ని మార్చే బాధ్యత తీసుకున్నారు. ఇది చాలా గొప్ప విషయం",
    next: "coaching-name",
    isShown: false,
  },
  "coaching-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "coaching-name",
    question: {
      msg: "మీ కోచింగ్ సెంటర్ పేరు నమోదు చేయండి?",
      options: [],
    },
    next: "coaching-size-intro",
    isShown: false,
    switchScreen: true,
  },
  "coaching-number-intro": {
    type: "simple",
    id: "coaching-number-intro",
    msg: "మీరు టీచర్ ప్రెన్యూర్ కావడానికి ఒక్క అడుగు దూరంలో ఉన్నారు!",
    next: "coaching-number-intro-2",
    isShown: false,
  },
  "coaching-number-intro-2": {
    type: "simple",
    id: "coaching-number-intro-2",
    msg: "మీరు ఆఫ్లయిన్ కోచింగ్ను నిర్వహించవచ్చు మరియు ఆన్లైన్ కోచింగ్ వ్యాపారాన్నీ అభివృద్ధి చేసుకోవచ్చు.",
    next: "coaching-number",
    isShown: false,
  },

  // "coaching-number-intro-2": {
  //   type: "simple",
  //   id: "coaching-number-intro-2",
  //   msg: "మీ కోచింగ్ సంస్ధ గురించి మాకు మరిన్ని వివరాలు అవసరం అవుతాయి.",
  //   next: "coaching-number",
  //   isShown: false,
  // },
  "coaching-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "coaching-number",
    question: {
      msg: "మీ కాంటాక్ట్ నెంబర్ నమోదు చేయండి?",
      options: [],
    },
    next: "coaching-name",
    isSubmitted: false,
  },
  "online-course-number-intro": {
    type: "simple",
    id: "online-course-number-intro",
    msg: "మీరు సోల్ ప్రెన్యూర్ అవ్వడానికి ఒక్క అడుగు దూరంలో ఉన్నారు!",
    next: "online-course-number",
    isShown: false,
  },
  "online-course-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "online-course-number",
    question: {
      msg: "మీ కాంటాక్ట్ నెంబర్ నమోదు చేయండి?",
      options: [],
    },
    next: "online-course-name",
    isSubmitted: false,
  },

  "online-course-name": {
    type: "input",
    subtype: "text",
    key: "instituteName",
    id: "online-course-name",
    question: {
      msg: "మీ పేరు నమోదు చేయండి?",
      options: [],
    },
    next: "online-course-is-creator",
    isSubmitted: false,
    switchScreen: true,
  },

  "online-course-is-creator": {
    type: "radio",
    id: "online-course-is-creator",
    key: "online-course-is-creator",
    question: {
      msg: "మీకు యూట్యూబ్ ఛానల్ లేదా ఇంస్టాగ్రామ్ ఖాతా ఉందా?",
      options: [
        {
          value: "Yes",
          key: "1",
          next: "online-course-subscribers",
        },
        {
          value: "No",
          key: "0",
          next: "youtuber-categories",
        },
      ],
    },
    next: "online-course-subscribers",
    isShown: false,
  },

  "online-course-subscribers": {
    type: "radio",
    id: "online-course-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "మీకు ఎంత మంది సబ్ స్క్రైబర్స్/ ఫాలోవర్స్ ఉన్నారు?",
      options: [
        {
          value: "1000 కంటే తక్కువ",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10000 కంటే ఎక్కువ",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "coaching-size-intro": {
    type: "simple",
    id: "coaching-size-intro",
    msg: "మీ కోచింగ్ గురించి మరికొన్ని వివరాలు కావాలి",
    next: "coaching-size",
    isShown: false,
  },

  "coaching-size": {
    type: "radio",
    id: "coaching-size",
    key: "numberOfStudents",
    question: {
      msg: "మీరు ఎంత మంది విద్యార్ధులకి బోధిస్తున్నారు?",
      options: [
        {
          value: "0 - 50 విద్యార్థులు",
          key: "0-100",
          next: "0-100-intro",
        },
        {
          value: "50 - 250 విద్యార్థులు",
          key: "100-250",
          next: "100-250-intro",
        },
        { value: "250 + విద్యార్థులు", key: "250+", next: "250-intro" },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },

  "0-100-intro": {
    type: "simple",
    id: "0-100-intro",
    msg: "బాగుంది! 50 మంది విద్యార్థుల వరకు ఉన్న విద్యావేత్తలు క్లాస్ ప్లస్ లో ఆన్లైన్ కోర్సులని విక్రయించడం మరియు లైవ్ క్లాసులు తీసుకోవడం ద్వారా సంవత్సరానికి దాదాపు రూ. 10  లక్షల వరకు సంపాదిస్తారు.",
    next: "isRecordedContent",
    isShown: false,
  },
  "100-250-intro": {
    type: "simple",
    id: "100-250-intro",
    msg: "అద్భుతం! ఆన్లైన్ కోర్సులని విక్రయించడం మరియు లైవ్ క్లాసులు తీసుకోవడం ద్వారా క్లాస్ ప్లస్ ని ఉపయోగిస్తున్న ఇతరుల మాదిరిగానే మీరు కూడా సంవత్సరానికి రూ.30 లక్షల వరకు సంపాదించే అవకాశం ఉంది.",
    next: "isRecordedContent",
    isShown: false,
  },
  "250-intro": {
    type: "simple",
    id: "250-intro",
    msg: "వావ్! ఆన్లైన్ కోర్సులని విక్రయించడం మరియు లైవ్ క్లాసులు తీసుకోవడం ద్వారా క్లాస్ ప్లస్ తో మీ సంవత్సర ఆదాయాన్ని  రూ.60 లక్షల వరకు పెంచుకోవడానికి సిద్ధంగా ఉండండి.",
    next: "isRecordedContent",
    isShown: false,
  },
  "app-features": {
    type: "multiSelect",
    id: "app-features",
    key: "features",
    question: {
      msg: "మీరు అప్ ని ఎలా ఉపయోగించాలనుకుంటున్నారు?",
      options: [
        {
          value: "ఆన్లైన్ కోర్సులని విక్రయించండి",
          key: "sell-courses",
        },
        {
          value: "లైవ్ క్లాస్సులని తీసుకోండి",
          key: "live-classes",
        },
        { value: "ఆన్లైన్ టెస్ట్ నిర్వహించండి", key: "online-test" },
        { value: "బ్యాచ్ నిర్వహణ", key: "fee-attendance" },
      ],
    },
    next: "app-features-intro-2",
    isShown: false,
    isSkippable: true,
  },

  "coaching-categories": {
    type: "dropdown",
    id: "coaching-categories",
    key: "skillCategory",
    question: {
      msg: "మీరు ఏ సబ్జెక్టులని బోధిస్తారు?", // new content
      options: [
        { value: "స్కూల్ బోర్డు ఎగ్జామ్స్", key: "school-board-exams" },
        {
          value: "కాలేజీ/యూనివర్సిటీ ప్రవేశ పరీక్షలు",
          key: "college-university-entrance-exams",
        },
        {
          value: "కాలేజీ/యూనివర్సిటీ సెమిస్టర్ పరీక్షలు",
          key: "college-university-semester-exams",
        },
        { value: "ప్రభుత్వ ఉద్యోగ పరీక్షలు", key: "govt-job-exams" },
        { value: "సీఏ, సీఎస్ , సీఎంఏ, సీఎఫఏ కోర్సులు", key: "professional-courses" },
        { value: "నాన్ అకాడమిక్ కోర్సులు", key: "non-academic-courses" },
        { value: "ఇతర కోర్సులు", key: "others" },
      ],
    },
    next: "learnGrowthTips",
    isShown: false,
  },

  isRecordedContent: {
    type: "radio",
    id: "isRecordedContent",
    key: "isRecordedContent",
    question: {
      msg: "ఆన్లైన్ కోచింగ్ ప్రారంభించడానికి మీ దగ్గర రికార్డెడ్ లెక్చర్స్ ఉన్నాయా?",
      options: [
        {
          value: "అవును, ఉన్నాయి",
          key: 1,
          next: "isRecordedYes",
        },
        {
          value: "లేదు, నేను రూపొందిస్తాను",
          key: 0,
          next: "isRecordedNo",
        },
      ],
    },
    next: "coaching-categories",
    isShown: false,
    isSkippable: true,
  },
  isRecordedYes: {
    type: "simple",
    id: "isRecordedYes",
    msg: "అద్భుతం! మీకు తెలుసా మీరు మీ యాప్ ద్వారా ఈ లెక్చర్స్ ని అమ్మి ఇంకా ఎక్కువ డబ్బు సంపాదించవచ్చు",
    next: "coaching-categories",
    isShown: false,
  },
  isRecordedNo: {
    type: "simple",
    id: "isRecordedNo",
    msg: "చింతించకండి, మా యాప్ లో వీడియోస్ రూపొందించటం చాలా సులభం. మీకు కావాల్సిందల్లా కంటెంట్  మాత్రమే!",
    next: "coaching-categories",
    isShown: false,
  },
  "coaching-email": {
    type: "input",
    subtype: "text",
    id: "coaching-email",
    key: "email",
    question: {
      msg: "మీ ఈమెయిల్ అడ్రస్ ని నమోదు చేయండి.",
      options: [],
    },
    next: "coaching-categories",
    isShown: false,
  },
  state: {
    type: "dropdown",
    id: "state",
    key: "state",
    question: {
      msg: "మీ ప్రాంతంలో క్లాస్ ప్లస్ ని ఉపయోగిస్తున్న ప్రముఖ కోచింగ్ సంస్థల గురించి తెలుసుకోవడానికి మీ రాష్ట్రాన్ని ఎంచుకోండి",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "state-youtubers": {
    type: "dropdown",
    id: "state-youtubers",
    key: "state",
    question: {
      msg: "మీ ప్రాంతంలో క్లాస్ ప్లస్ ని ఉపయోగిస్తున్న కంటెంట్ క్రియేటర్స్ గురించి తెలుసుకోవడానికి మీ రాష్ట్రాన్ని ఎంచుకోండి",
      options: [],
    },
    next: "end",
    isShown: false,
  },
  "youtuber-categories-intro": {
    type: "simple",
    id: "youtuber-categories-intro",
    msg: "ఆద్భుతం! మీరు ఇండియాలోనే ప్రముఖ కంటెంట్ క్రియేటర్ అవ్వడానికి సరిఅయిన మార్గంలోనే ఉన్నారు",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-categories": {
    type: "dropdown",
    id: "youtuber-categories",
    key: "skillCategory",
    question: {
      msg: "మీరు ఎటువంటి కంటెంట్ క్రియేట్ చేస్తారు?", // new content
      options: [
        { value: "స్కూల్ బోర్డు ఎగ్జామ్స్", key: "school-board-exams" },
        {
          value: "కాలేజీ/యూనివర్సిటీ ప్రవేశ పరీక్షలు",
          key: "college-university-entrance-exams",
        },
        {
          value: "కాలేజీ/యూనివర్సిటీ సెమిస్టర్ పరీక్షలు",
          key: "college-university-semester-exams",
        },
        { value: "ప్రభుత్వ ఉద్యోగ పరీక్షలు", key: "govt-job-exams" },
        { value: "సీఏ, సీఎస్ , సీఎంఏ, సీఎఫఏ కోర్సులు", key: "professional-courses" },
        // { value: "Music", key: "music" },
        {
          value: "వ్యక్తిత్వ వికాసం/బిజినెస్ కోచ్",
          key: "personality-development",
        },
        { value: "స్టాక్ మరియు క్రిప్టో ట్రేడింగ్ ", key: "stock-trading" },
        // {value: "ఫోటోగ్రఫీ", key: "photography" },
        // { value: "లాంగ్వేజ్ ట్రైనింగ్", key: "languages" },
        { value: "ఫిట్ నెస్", key: "fitness" },
        { value: "సాఫ్ట్వేర్ ట్రైనింగ్", key: "software-training" },
        { value: "ఇతర కోర్సులు", key: "others" },
      ],
    },
    isShown: false,
    next: "learnGrowthTips",
  },
  "youtuber-name": {
    type: "input",
    subtype: "text",
    id: "youtuber-name",
    key: "instituteName",
    question: {
      msg: "దయచేసి మీ యూట్యూబ్ ఛానల్/ ఇంస్టాగ్రామ్ ఖాతా పేరు నమోదు చేయండి",
      options: [],
    },
    next: "youtuber-subscribers",
    isShown: false,
    switchScreen: true,
  },
  "youtuber-number": {
    type: "input",
    subtype: "number",
    id: "youtuber-number",
    key: "phoneNumber",
    question: {
      msg: "దయచేసి మీ కాంటాక్ట్ నంబరును నమోదు చేయండి",
      options: [],
    },
    next: "youtuber-name",
    isSubmitted: false,
  },
  "youtuber-subscribers": {
    type: "radio",
    id: "youtuber-subscribers",
    key: "numberOfStudents",
    question: {
      msg: "మీకు ఎంత మంది సబ్ స్క్రైబర్స్/ ఫాలోవర్స్ ఉన్నారు? ",
      options: [
        {
          value: "1000  కంటే తక్కువ",
          key: "less-than-1000",
          next: "less-than-1000-intro",
        },
        {
          value: "1,000 - 10,000",
          key: "1000-10000",
          next: "1000-10000-intro",
        },
        {
          value: "10000 కంటే ఎక్కువ",
          key: "10000+",
          next: "10000-intro",
        },
      ],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "less-than-1000-intro": {
    type: "simple",
    id: "less-than-1000-intro",
    msg: "బాగుంది! 1000 మంది సబ్స్క్రైబర్స్/ఫాలోవర్లు వరకు ఉన్న కంటెంట్ క్రియేటర్స్  క్లాస్ ప్లస్ లో సంవత్సరానికి దాదాపు రూ. 10  లక్షల వరకు సంపాదిస్తారు.",
    next: "youtuber-categories",
    isShown: false,
  },
  "1000-10000-intro": {
    type: "simple",
    id: "1000-10000-intro",
    msg: "అద్భుతం! క్లాస్ ప్లస్ ని ఉపయోగిస్తున్న ఇతరుల మాదిరిగానే మీరు కూడా సంవత్సరానికి రూ.30 లక్షల వరకు సంపాదించే అవకాశం ఉంది.",
    next: "youtuber-categories",
    isShown: false,
  },
  "10000-intro": {
    type: "simple",
    id: "10000-intro",
    msg: "వావ్! క్లాస్ ప్లస్ తో మీ సంవత్సర ఆదాయాన్ని  రూ.60 లక్షల వరకు పెంచుకోవడానికి సిద్ధంగా ఉండండి.",
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-email": {
    type: "input",
    subtype: "email",
    id: "youtuber-email",
    key: "email",
    question: {
      msg: "మీ ఈమెయిల్ అడ్రస్ ని నమోదు చేయండి.",
      options: [],
    },
    next: "youtuber-categories",
    isShown: false,
  },

  "youtuber-number-intro": {
    type: "simple",
    id: "youtuber-number-intro",
    msg: "మీరు కంటెంట్ ప్రెన్యూర్ అవ్వడానికి కొన్ని అడుగుల దూరంలో ఉన్నారు!",
    next: "youtuber-number-intro-2",
    isShown: false,
  },
  "youtuber-number-intro-2": {
    type: "simple",
    id: "youtuber-number-intro-2",
    msg: "దాదాపు 50,000 యూట్యూబ్/ఇంస్టాగ్రామ్ కంటెంట్ క్రియేటర్స్ క్లాస్ ప్లస్ ద్వారా వారి ఆదాయాన్ని 3 నెలల్లో మూడు రేట్లు పెంచుకున్నారు",
    next: "youtuber-number",
    isShown: false,
  },
  "youtuber-name-exit": {
    type: "simple",
    id: "youtuber-name-exit",
    msg: "మీరు కంటెంట్ ప్రెన్యూర్ అవ్వడానికి కొన్ని అడుగుల దూరంలో ఉన్నారు!",
    next: "youtuber-number",
    isShown: false,
  },

  "teacher-consent": {
    type: "radio",
    id: "teacher-consent",

    question: {
      msg: "మీరు మీ సొంత కోచింగ్ బిజినెస్ ప్రారంభించి టీచర్ ప్రెన్యూర్ అవ్వడానికి సిద్ధంగా ఉన్నారా?",
      options: [
        {
          value: "అవును, కచ్చితంగా!",
          key: "yes",
          next: "teacher-name-intro",
        },
        {
          value: "నేను ఎలా ప్రారంభించాలో నాకు తెలియడం లేదు :(",
          key: "no",
          next: "teacher-name-negative-intro",
        },
      ],
    },
    next: "teacher-name",
    isShown: false,
  },
  "teacher-name-intro": {
    type: "simple",
    id: "teacher-name-intro",
    msg: " అద్భుతం! మీకు మీ ఆన్లైన్ జర్నీ లో మార్గనిర్దేశం చేయడానికి మరికొన్ని వివరాలు కావాలి!",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name-negative-intro": {
    type: "simple",
    id: "teacher-name-negative-intro",
    msg: "చింతించకండి, మీ ఆన్లైన్ తొలి అడుగులలో మేము మీకు మార్గనిర్దేశం చేస్తాము",
    next: "teacher-number",
    isShown: false,
  },
  "teacher-name": {
    type: "input",
    key: "instituteName",
    subtype: "text",
    id: "teacher-name",
    question: {
      msg: "మీ పేరు ని నమోదు చేయండి",
      options: [],
    },
    next: "teacher-click-more",
    isShown: false,
    switchScreen: true,
  },

  "teacher-number": {
    type: "input",
    subtype: "number",
    key: "phoneNumber",
    id: "teacher-number",
    question: {
      msg: "మీ కాంటాక్ట్ నెంబర్ ని నమోదు చేయండి",
      options: [],
    },
    next: "teacher-name",
    isSubmitted: false,
  },
  "teacher-click-more": {
    type: "radio",
    id: "teacher-click-more",
    question: {
      msg: "మీకు తెలుసా క్లాస్ ప్లస్ ద్వారా మీ ఆన్లైన్ బిజినెస్ పెంచుకోవచ్చు మరియు సులభంగా నేషనల్ బ్రాండ్ గా అవొచ్చు.",
      options: [
        {
          value: "ఇంకా తెలియజేయండి",
          key: "yes",
        },
      ],
    },
    next: "teacher-curious",
  },
  "teacher-curious": {
    type: "radio",
    id: "teacher-curious",
    question: {
      msg: "క్లాస్ ప్లస్ తో మీరు ఆన్లైన్ క్లాస్సేస్, రికార్డెడ్ లెక్చర్స్ సేల్, అసైన్మెంట్స్ షేర్ చేయడంతో పాటు మరెన్నో చేయొచ్చు!",
      options: [
        {
          value: "నేను మరింత తెలుసుకోవడానికి ఉత్సాహంగా ఉన్నాను",
          key: "yes",
        },
      ],
    },
    next: "teacher-recorded-info",
  },
  "teacher-recorded-info": {
    type: "simple",
    id: "teacher-recorded-info",
    msg: " రికార్డెడ్  లెక్చర్స్ ఉన్న టీచర్స్ ఆన్లైన్ కోర్సెస్ అమ్మడం ద్వారా అధిక ఆదాయాన్ని సంపాదిస్తున్నారు",
    next: "coaching-categories",
    isShown: false,
  },
  student: {
    type: "radio",
    id: "student",
    key: "student-consent",
    question: {
      msg: "హలో, మిమ్మల్ని ఇక్కడ చూడటం మాకు చాల సంతోషంగా ఉంది. మీ కోచింగ్ ఇన్స్టిట్యూట్ ఆన్లైన్లో టీచ్ చేయడానికి మొబైల్ యాప్ ఉపయోగిస్తుందా?",
      options: [
        {
          value: "లేదు",
          key: "no",
          next: "share-classplus",
        },
        {
          value: "అవును",
          key: "yes",
          next: "share-classplus-yes",
        },
      ],
    },
    next: "share-classplus",
  },

  "share-classplus": {
    type: "simple",
    id: "share-classplus",
    msg: "వారిని క్లాస్ ప్లస్ ట్రై చేయమని చెప్పి రూ.500 అమెజాన్ వౌచెర్ గెలిచే అవకాశం పొందండి. ",
    next: "student-teacher-number",
    isShown: false,
  },

  "student-teacher-number": {
    type: "input",
    subtype: "number",
    id: "student-teacher-number",
    key: "phoneNumber",
    question: {
      msg: "మీ నెంబర్ ని నమోదు చేయండి",
      options: [],
    },
    next: "student-teacher-name",
    isSubmitted: false,
  },
  "student-teacher-name": {
    type: "input",
    subtype: "text",
    id: "student-teacher-name",
    key: "instituteName",
    question: {
      msg: "మీ పేరు ని నమోదు చేయండి",
      options: [],
    },
    next: "learnGrowthTips",
    isSubmitted: false,
  },

  "share-classplus-yes": {
    type: "simple",
    id: "share-classplus-yes",
    msg: "వారిని క్లాస్ ప్లస్ ట్రై చేయమని చెప్పి రూ.500 అమెజాన్ వౌచెర్ గెలిచే అవకాశం పొందండి.",
    next: "student-teacher-number",
    isShown: false,
  },

  learnGrowthTips: {
    type: "radio",
    id: "learnGrowthTips",
    key: "learnGrowthTips",
    question: {
      msg: "మీకు గ్రోత్ చిట్కాలు కావాలా?",
      options: [
        {
          value: "అవును",
          key: 1,
          next: "end",
        },
        {
          value: "లేదు",
          key: 0,
          next: "end",
        },
      ],
    },
    next: "end",
    isShown: false,
    isSkippable: true,
  },
};





