import whatsappLogo from '../../assets/Images/whatsapp-logo.svg';
import './JoinWhatsappButton.css';

const JoinWhatsappButton = ({handleClick, text, buttonText}) => {
    return (
        <div className='join-whatsapp-wrapper'>
            <div className='join-whatsapp-text'>
            {text}
            </div>
            <button onClick={handleClick} className='join-whatsapp-button'>
                <img className="join-whatsapp-img" src={whatsappLogo} alt="" />
                {buttonText}
            </button>
        </div>
    )
}

export default JoinWhatsappButton;