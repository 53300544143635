import classplusLogo from "../../assets/Images/classplusLogo.png";
import messageEdit from "../../assets/Images/message-edit.svg";
import playCircle from "../../assets/Images/play-cricle.svg";
import announce from "../../assets/Images/Announce.svg";
import {conversationsEG } from "../../data/contents";
import { ButtonTexts } from "../../data/contents";
import "./headings.css";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import globe from "../../assets/Images/globe.png";
// import posterimg from "../../assets/Images/postermodalimg.jpg";
import posterimg from "../../assets/Images/posterMobile.jpg";
import closemodalicon from "../../assets/Images/closemodalicon.jpeg";
import redDot from "../../assets/Images/redDot.svg";
import axios from "axios";
import { baseUrl } from "../../utils/api";
import i18n from "../../i18n";
import ModalCross from "../../assets/Images/modal-cross.svg";
import { useMediaQuery } from "react-responsive";
import trackEvent from "../utils";
import {redirectWebinarPage} from "../../utils/redirectWebinarPage"

const Headings = ({
  language,
  setMobileLan,
  setMobileCommunityUrl,
  userInputs,
  setMobileUid,
  isNumberSubmitted,
  coachingName,
  showRadarScreen,
  mobileLan,
}) => {
  const { t } = useTranslation();
  const [uid,setUid]=useState()
  const [headingTexts , setHeadingTexts] = useState()
  const [headingText, setHeadingText] = useState();
  const [buttonText, setButtonText] = useState(language === "EN" ? "Telugu":"English")
  const [showButton, setShowButton] = useState(false) 
  const [apiSet, setApiSet]=useState()
  const isSmallScreen = useMediaQuery({ query: "(max-width: 800px)" });
  
  const [showModal, isShowModal] = useState(false);
  const [posterModal, setPosterModal] = useState(false)
  const [liveNow, isLiveNow] = useState(false);

  useEffect(() => {
    setMobileLan(localStorage.getItem('lang'))
    const url = new URL(window.location.href);
    let utm_source = url.searchParams.get("utm_source");
    let utm_campaign = url.searchParams.get("utm_campaign");
    let utm_medium = url.searchParams.get("utm_medium");
    let utm_content = url.searchParams.get("utm_content");
    let utm_term = url.searchParams.get("utm_term");
    let gclID = url.searchParams.get("gclid");
    getUUID(utm_campaign, utm_medium, utm_source, utm_content, utm_term, gclID);

    if(isSmallScreen) {
      sessionStorage.setItem("poster-triggered", true);
      setTimeout(() =>{
          if(sessionStorage.getItem("poster-triggered") === "true"){
            setPosterModal(true)
            isLiveNow(true)
            sessionStorage.setItem("poster-triggered", false);
          }
      },7000)
    }
  }, []);

  const getUUID = async (
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    utm_term,
    gclID
  ) => {
    try {
      const fbp = document.cookie
        .split(";")
        .filter((c) => c.includes("_fbp="))
        .map((c) => c.split("_fbp=")[1]);
      const fbc = document.cookie
        .split(";")
        .filter((c) => c.includes("_fbc="))
        .map((c) => c.split("_fbc=")[1]);

      let fbpCookie = fbp && fbp[0];
      let fbcCookie = fbc && fbc[0];

      const { data } = await axios.post(`${baseUrl}/growth/saveLead`, {
        utm_campaign,
        utm_medium,
        utm_source,
        utm_content,
        utm_term,
        gclId: gclID,
        fbp: fbpCookie,
        fbc: fbcCookie,
        leadIntent:"clp-old",
      });
      setUid(data.data);
      setMobileUid(data?.data)
      const lang=data?.data?.languageDetails?.language?.toUpperCase() ? data?.data?.languageDetails?.language?.toUpperCase() : 'EN';
      if(!localStorage.getItem('lang')){
        localStorage.setItem("lang",lang);
      }
      let updateLocalLanguage =  localStorage.getItem('lang');
      setMobileLan(updateLocalLanguage)
      i18n.changeLanguage(updateLocalLanguage)
      setApiSet(lang)
      setHeadingText(conversationsEG?.[`${updateLocalLanguage}`][1]);
      setMobileCommunityUrl(data?.data?.languageDetails?.communityUrl)
      setShowButton(lang === "EN" ? false:true)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(()=>{
    if(localStorage.getItem('lang')=== 'EN')
    {
      setButtonText(ButtonTexts?.[`${apiSet}`])
      setHeadingText(conversationsEG?.[`${language}`]?.[1])
    }
  },[apiSet])

  const handleLanguages = ()=>{
    if(localStorage.getItem('lang')=='EN'){
      localStorage.setItem('lang',apiSet)
    } else{
      localStorage.setItem('lang','EN')
    }
    window.location.href='/';
  }
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('lang'));
    setHeadingText(conversationsEG?.[`${language}`]?.[1]);
    setHeadingTexts(conversationsEG?.[`${mobileLan}`]);
    let index = 0;
    const interval = setInterval(() => {
      if (index < headingTexts?.length - 1) {
        setHeadingText(headingTexts[index + 1]);
        index++;
      } else {
        index = 0;
        setHeadingText(headingTexts?.[index]);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [mobileLan,headingTexts]);
  const handleButtonClick = () => {
    redirectWebinarPage(
      "https://klr.bz/CLSPLS/0Fkay/",
      "Livebuttonnew"
    );
    trackEvent("redirectWebinar","click","bookFreeDemoHeader");
  };

  return (
    <div className={userInputs[Object.keys(userInputs)[0]] ? `heading` : ""}>
      <div className="nav-mobile-view">
      <img width={100} height={40} alt="classplus-logo" src={classplusLogo} />
      
          {/* <div className="nav-lang-box" onClick={handleLanguages}>
          <img src={globe} alt="globe" />
          <p>{buttonText}</p>
        </div> */}
        
      <div className="live-now-mobile" onClick={handleButtonClick}>
        {/* <img class="redLive" src={redDot} alt="" />
         <p className="live-text">Watch Demo</p> */}
         Book a free demo
        </div>
      
      </div>

      <h3 className="text-2xl mt-6 text-colorHeading font-semibold" style={{marginTop:"60px"}}>
        {isNumberSubmitted ? (
          <>
            {/* {showRadarScreen ? (
              <p>
                {t("components.Headings.top3", "These are the top 3")}{" "}
                {userInputs["youtuber-name"] || userInputs["online-course-name"]
                  ? t(
                      "components.Headings.content creaters",
                      "content creators in your category"
                    )
                  : t(
                      "components.Headings.educators",
                      "educators in your regions"
                    )}
              </p>
            ) : (
              <>
                <span className="border-b-4  border-colorWhite">
                  {t("components.Headings.Hi", "Hi")}
                </span>
                , {coachingName}
              </>
            )} */}
            {showRadarScreen ? (
              null
            ) : (
              <>
                <span className="border-b-4  border-colorWhite">
                  {t("components.Headings.Hi", "Hi")}
                </span>
                , {coachingName}
              </>
            )}
          </>
        ) : (
          <>
            {/* <span className="border-b-4  border-colorWhite">Create</span> your
            online <br /> presence with Classplus */}
            {t("components.Headings.Afre you ready", "Are you ready to be a")}
            <br />
            <span className="text-colorWhite border-b-2 border-colorHeading ">
              {headingText}?
            </span>
            {/* <span className="mb-4">
              <span className="js-nametag text-colorWhite border-b-2 border-colorHeading">Teacherpreneur</span>
              <span className="js-nametag text-colorWhite border-b-2 border-colorHeading">Solopreneur</span>
              <span className="js-nametag text-colorWhite border-b-2 border-colorHeading">Videopreneur</span>
            <span className="js-nametag text-colorWhite border-b-2 border-colorHeading">Contentpreneur</span> 
            </span>  */}
          </>
        )}
      </h3>
      {!isNumberSubmitted && (
        <>
          <p className={`mt-12 mb-6 ${userInputs["name"] ? "hideInfo" : ""}`} style={{width:"85%"}}>
            <b>
              Grow Your Online Teaching Brand
            </b>
          </p>

          <div className={`mt-6 mb-6 ${userInputs["name"] ? "hideInfo" : ""}`}>
            <span className="textStyleMob">
              <img src={messageEdit} className="imgStyleMob" />
              Take Live Classes
            </span>
            <span className="textStyleMob">
              <img src={playCircle} className="imgStyleMob" />
              Sell Courses Online
            </span>
            <span className="textStyleMob">
              <img src={announce} className="imgStyleMob" />
              Get Marketing Assistance
            </span>
          </div>
        </>
      )}

      {isNumberSubmitted && (
        <p>
          {showRadarScreen ? (
            <>{}</>
          ) : (
            <>{t(
              "components.Headings.Something special",
              "We have something special in store for you"
            )}</>
          )}
        </p>
      )}

      {posterModal && posterimg && <div class="poster-modal">
      <img className="poster-img" onClick={()=>window.location = "https://bit.ly/eWebinarWebsitePopup"} src={posterimg} />
       <img class="poster-modal-close" src={closemodalicon} onClick={()=>setPosterModal(false)} />
      </div>
      }
    </div>
  );
};

export default Headings;
