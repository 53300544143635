import React, { useContext, useState } from "react";
import { ConversationContext } from "./Conversation";
import circle from "../assets/Images/circle.svg";
import selectedCircle from "../assets/Images/selectedCircle.svg";
import tick from "../assets/Images/tick.svg";
export const MultiSelect = () => {
  const { options, setValue, id, next, type } = useContext(ConversationContext);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const optionsSelector = (selectedOptionArr, valueToPass) => {
    if (selectedOptionArr.includes(valueToPass)) {
      const data = selectedOptionArr.filter((item) => item !== valueToPass);
      setValue(id, data, false, next, type);
      setSelectedOptions(data);
    } else {
      setSelectedOptions([...selectedOptionArr, valueToPass]);
      setValue(id, [...selectedOptionArr, valueToPass], false, next, type);
    }
  };

  const MemoisedComponent = React.memo(({ option, isVisible, first }) => {
    return (
      <React.Fragment>
        <button
          disabled={isSubmitted}
          key={option?.value || option}
          className={`mt-2 p-2 rounded relative w-4/5 font-semibold ${
            isVisible
              ? " bg-colorWhite border-2 border-colorWhite text-colorSecondary "
              : "bg-colorTransparent border-2 border-colorSecondary text-colorWhite"
          }`}
          onClick={(e) => {
            optionsSelector(selectedOptions, e.target.innerText);
          }}
        >
          {option?.value || option}

          <img
            onClick={(e) => {
              e.stopPropagation();
              optionsSelector(selectedOptions, e.target.parentNode.innerText);
            }}
            src={isVisible ? selectedCircle : circle}
            alt="selectedCircle"
            className={`multiSelectCheck h-6 w-6 inline-block absolute left-3 top-2  `}
          />
        </button>
        {isSubmitted && first === 0 ? (
          <div className="answerArrow multiSelectArrow"></div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  });

  return (
    <div className="grid justify-items-end ">
      <div className="w-full mt-2 mb-1 text-xs">Select Multiple</div>
      {options.map((option, index) => {
        const isVisible = selectedOptions?.includes(option.value);

        return (
          <MemoisedComponent
            option={option}
            isVisible={isVisible}
            key={index}
            first={index}
          />
        );
      })}
      <button
        disabled={!selectedOptions.length}
        className={`flex w-10 rounded-lg justify-center items-center absolute h-10 left-2 bottom-1 ${
          selectedOptions.length ? "bg-colorSecondary" : "bg-colorWhite"
        }  ${isSubmitted ? "hidden" : ""}`}
        onClick={() => {
          setIsSubmitted(true);
          setValue(id, selectedOptions, true, next, type);
        }}
      >
        <img
          src={tick}
          alt="tick"
          className={`w-4 ${selectedOptions.length ? "tick" : ""}`}
        />
      </button>
    </div>
  );
};
