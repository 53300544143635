import React from "react";

export const Question = (props) => {
  return (
    <>
      <div className="question text-left mt-3 p-2 font-semibold bg-colorSecondary rounded w-4/5">
        {props.question}
      </div>
      <div className="questionArrow"></div>
    </>
  );
};
