import "./souravDadaImage.css";

const SouravDadaImage = (props) => {
  return (
    <div className={
      `souravDadaImage
      ${props.dadaFadeOut && "dadaFadeOut"}`
    }>

    </div>
  )
}

export default SouravDadaImage;
