import axios from "axios";
import { baseUrl } from "./api";
export const getDisplayName = (name = "ABC") => {
  const value = name.toLowerCase();
  if (
    value.includes("class") ||
    value.includes("coach") ||
    value.includes("institute") ||
    value.includes("academy") ||
    value.includes("centre") ||
    value.includes("center") ||
    value.includes("tution") ||
    value.includes("tutor")
  ) {
    return name;
  } else {
    return `${name} Classes`;
  }
};
export const initials = (coachingName = "") => {
  if (coachingName) {
    return coachingName
      .split(" ")
      .map((item) => item[0] && item[0].toUpperCase())
      .join("");
  } else {
    return "ABC";
  }
};
/*
  This function is used to make api call with each growth save
  it will also open whatsapp if flag is passed to open whatsapp
  - communityUrl
  - payload
  - openWhatsapp (flag to decide whether to open whatsapp)
*/
export const fetchGrowthAndWhatsapp = async (communityUrl, payload, openWhatsapp=false ) => {
  try {
    await axios.patch(`${baseUrl}/growth/saveDetails`, {
      ...payload,
      leadIntent: "clp-old",
    });
    // navigate("/ThankYou");
  } catch (err) {
    console.log(err.message);
  }
  if(openWhatsapp){
    window.location.href=communityUrl? `https://bit.ly/3tnaFwf` : 'https://bit.ly/3tnaFwf';
  }
};